import _ from "lodash";
import { fotobotApiAxiosInstance as axiosInstance } from "../utils/axiosInstances";

export async function registerNewUser(name, emailOrPhoneNumber) {
  const { data } = await axiosInstance.post(`/register`, {
    name,
    emailOrPhoneNumber,
  });
  return data;
}

export async function sendVerificationCode(emailOrPhoneNumber) {
  const { data } = await axiosInstance.post(`/sendVerificationCode`, {
    emailOrPhoneNumber,
  });
  return data;
}

export async function loginWithOTP(emailOrPhoneNumber, verificationCode) {
  if (!emailOrPhoneNumber || !verificationCode) {
    throw new Error(
      "Payload must contain email/phoneNumber and verificationCode"
    );
  }

  const { data } = await axiosInstance.post(
    `/verifyEmailOrPhoneNumber`,
    _.pickBy({ emailOrPhoneNumber, verificationCode })
  );

  return data;
}

export async function loginWithGoogle(credential) {
  const { data } = await axiosInstance.post(`/auth/google`, { credential });
  return data;
}

export async function verifyWeddingPasscode(weddingId, passcode) {
  const { data } = await axiosInstance.post(`/verifyWeddingPasscode`, {
    weddingId,
    passcode,
  });
  return data;
}

export async function logout() {
  const { data } = await axiosInstance.post(`/logout`);
  return data;
}
