import cx from "classnames";
import React from "react";

import CustomTextField from "@@components/common/CustomTextField";
import { isValidEmailOrPhone } from "@@utils/inputValidationUtils";

function EmailOrPhoneNumberField({
  register,
  error,
  label,
  className,
  required,
  ...restProps
}) {
  return (
    <CustomTextField
      className={cx(className, "email-or-phone-input")}
      label={label ?? `Email / Phone${required ? " *" : ""}`}
      error={!!error}
      helperText={error?.message ?? ""}
      {...register("emailOrPhoneNumber", {
        required: { value: required, message: "This field is required." },
        setValueAs: (v) => {
          const isPhoneNumber = /^(\d|\s|\+)+$/.test(v);
          return isPhoneNumber && !v.startsWith("+") ? `+91${v}` : v;
        },
        validate: isValidEmailOrPhone,
      })}
      size="normal"
      margin="normal"
      {...restProps}
    />
  );
}

export default EmailOrPhoneNumberField;
