import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useMachine } from "@xstate/react";
import cx from "classnames";
import React from "react";
import { useHistory } from "react-router-dom";

import CustomModal from "@@components/common/CustomModal";
import CreateWeddingFlowBasicDetailsFormWrapper from "@@components/common/CreateWeddingFlow/BasicDetailsFormWrapper";
import PaneHeading from "@@components/common/Headings/PaneHeading";
import SplashContent from "@@components/common/SplashContent";
import createWeddingFlowFsm from "@@components/common/CreateWeddingFlow/createWeddingFlowFsm";
import { CreateWeddingFlowFsmStateContext } from "@@contexts/CreateWeddingFlowFsmContexts";
import { createWedding } from "@@services/fotobot-api.service";
import { delayMs } from "@@utils";

import "animate.css";
import logoFilled from "@@img/logo256.jpeg";

// Questions
// How to reset state machine state when modal is closed / form is submitted?

export default function CreateWeddingModal({
  open,
  onClose,
  className,
  ...restProps
}) {
  const history = useHistory();
  const [fsmState, fsmSend] = useMachine(createWeddingFlowFsm);

  const handleClose = (...args) => {
    fsmSend("RESET");
    onClose(...args);
  };

  let errMsg;
  if (fsmState.value === "error") {
    const { createWeddingError: e } = fsmState.context;
    if (e.isAxiosError && e.response.status < 500) {
      errMsg = e.response.data.error;
    } else {
      errMsg = "Sorry, an error occurred. We're looking into it.";
    }
  }

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      className={cx(className, "create-wedding-modal")}
      {...restProps}
    >
      <CreateWeddingFlowFsmStateContext.Provider value={fsmState}>
        {fsmState.value === "askBasicEventDetails" ? (
          <>
            <PaneHeading sx={{ color: "grey.main", marginBottom: 0 }}>
              Wedding details
            </PaneHeading>

            <CreateWeddingFlowBasicDetailsFormWrapper
              id="create-wedding-flow-basic-details-form"
              onSubmit={handleSubmit}
              sx={{ marginBottom: "0.75rem" }}
              SubmitButtonProps={{ sx: { display: "none" } }}
            />

            <Box className="controls" sx={{ display: "flex", gap: "0.5rem" }}>
              <Button
                type="submit"
                form="create-wedding-flow-basic-details-form"
                size="large"
                variant="contained"
                sx={{ marginLeft: "auto" }}
              >
                Continue
              </Button>
            </Box>
          </>
        ) : fsmState.value === "waiting" ? (
          <Box sx={{ minHeight: "20rem" }} className="flex-center-content">
            <SplashContent />
          </Box>
        ) : fsmState.value === "error" ? (
          <Box
            className="flex-center-content"
            sx={{
              position: "relative",
              minHeight: "20rem",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="body2"
              sx={{ color: "error.main", textAlign: "center" }}
            >
              {errMsg}
            </Typography>

            <Box
              className="controls"
              sx={{ position: "absolute", bottom: 0, width: "100%" }}
            >
              <Button
                size="large"
                variant="outlined"
                onClick={() => fsmSend("BACK_BUTTON_PRESSED")}
              >
                Back
              </Button>
            </Box>
          </Box>
        ) : (
          <Box sx={{ minHeight: "20rem" }} className="flex-center-content">
            <SplashContent
              sx={{
                animationName: "fadeIn",
                animationDuration: "2s",
                animationIterationCount: 1,
              }}
              ImageProps={{ src: logoFilled, sx: { opacity: 1 } }}
              loadingText="Success! We'll take you to the wedding gallery shortly ..."
              TextProps={{ sx: { color: "grey.dark", fontWeight: 500 } }}
            />
          </Box>
        )}
      </CreateWeddingFlowFsmStateContext.Provider>
    </CustomModal>
  );

  async function handleSubmit(data) {
    fsmSend("BASIC_EVENT_DETAILS_PROVIDED", { data });

    const { name, date, role } = data;

    const dataToSubmit = {
      name,
      roleOfWeddingCreator:
        role === "RELATIVE_OR_FRIEND" ? "CLIENT_OTHER" : role,
      weddingEvents: [
        {
          name: "Wedding",
          // HACK: making an assumption that the wedding will be at
          //   6pm in the user's time zone
          // TODO: The user should be able to change this
          date: new Date(`${date} 18:00:00`).toISOString(),
        },
      ],
    };

    try {
      const {
        data: { slug },
      } = await createWedding(dataToSubmit);

      fsmSend("WEDDING_CREATED");

      await delayMs(2000);
      handleClose(null, "success");
      // TODO: nice page/view transition here?
      history.push(`/weddings/${slug}`);
    } catch (e) {
      fsmSend("ERROR_DURING_WEDDING_CREATION", { error: e });
      throw e;
    }
  }
}
