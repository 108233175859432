import cx from "classnames";

import CustomModal from "@@components/common/CustomModal";
import LoginComponent from "@@components/common/LoginComponent";

export default function LoginModal({ open, onClose, className, ...restProps }) {
  return (
    <CustomModal
      className={cx("login-modal", className)}
      open={open}
      onClose={onClose}
      {...restProps}
    >
      <LoginComponent headline="Login with your email or phone number" />
    </CustomModal>
  );
}
