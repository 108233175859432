import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React from "react";

export default function GoogleSignInButton({ context, ...restProps }) {
  const btnRef = React.useRef(null);

  React.useEffect(() => {
    window.google.accounts.id.renderButton(btnRef.current, {
      shape: "rectangular",
      size: "large",
      theme: "outline",
      text: context === "signup" ? "signup_with" : "signin_with",
      type: "standard",
      logo_alignment: "center",
    });
  }, [context]);

  return <Box component="div" ref={btnRef} {...restProps} />;
}

GoogleSignInButton.propTypes = {
  context: PropTypes.oneOf(["signup", "login"]),
};
GoogleSignInButton.defaultProps = {
  context: "signup",
};
