import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import React from "react";
import { useRouteMatch } from "react-router-dom";

import ReactionButton from "@@components/common/Buttons/ReactionButton";
import {
  WeddingMediaAppliedFiltersContext,
  WeddingMediaAppliedFiltersDispatchContext,
  WeddingMediaSelectedFiltersContext,
  WeddingMediaSelectedFiltersDispatchContext,
  WeddingMediaSavedFiltersContext,
  SetWeddingMediaSavedFiltersContext,
} from "@@contexts/WeddingMediaFiltersContexts";
import { getSavedMediaFilter } from "@@services/wedding-media.service";
import {
  countFilters,
  countChangesFromAppliedFiltersToSelectedFilters,
} from "@@utils/mediaFiltersUtils";
import NumPersonsFilter from "./NumPersonsFilter";
import PersonFilter from "./PersonFilter";
import PublicPrivateFilter from "./PublicPrivateFilter";
import SaveAppliedFiltersModal from "./SaveAppliedFiltersModal";

export default function MediaFilters({ wedding, sx }) {
  const routeMatch = useRouteMatch("/weddings/:weddingId/:tabName");
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const appliedMediaFilters = React.useContext(
    WeddingMediaAppliedFiltersContext
  );
  const appliedMediaFiltersDispatch = React.useContext(
    WeddingMediaAppliedFiltersDispatchContext
  );
  const selectedMediaFilters = React.useContext(
    WeddingMediaSelectedFiltersContext
  );
  const selectedMediaFiltersDispatch = React.useContext(
    WeddingMediaSelectedFiltersDispatchContext
  );
  const savedFilters = React.useContext(WeddingMediaSavedFiltersContext);
  const setSavedFilters = React.useContext(SetWeddingMediaSavedFiltersContext);

  const [curTab, setCurTab] = React.useState("applied"); // 'applied' | 'saved'
  const [saveSelectedFiltersModalOpen, setSaveSelectedFiltersModalOpen] =
    React.useState(false);

  const nFiltersApplied = countFilters(appliedMediaFilters);
  const nFiltersSelected = countFilters(selectedMediaFilters);
  const nChangesToAppliedFilters =
    countChangesFromAppliedFiltersToSelectedFilters(
      selectedMediaFilters,
      appliedMediaFilters
    );

  // if a saved filter is applied, but its data is not yet available (happens
  //   when a user opens a shared-filter link), we need to fetch it and update
  //   the filters UI with it
  React.useEffect(() => {
    (async () => {
      const { savedFilterUuid } = selectedMediaFilters;

      if (!(savedFilterUuid && countFilters(selectedMediaFilters) === 0)) {
        return;
      }

      const { data: savedFilterData } = await getSavedMediaFilter(
        wedding.id,
        savedFilterUuid
      );

      const action = {
        type: "SET_FILTERS",
        data: { ...savedFilterData.filters, savedFilterUuid },
      };
      selectedMediaFiltersDispatch(action);

      // we could uncomment the following line if we feel anal about
      //   keeping selectedMediaFilters and appliedMediaFilters in-sync,
      //   but there's no real need, plus it'll cause an unnecessary
      //   getMedia api call from inside the useWeddingMedia hook
      // appliedMediaFiltersDispatch(action);
    })();
  }, [wedding.id, selectedMediaFilters, selectedMediaFiltersDispatch]);

  // super hacky way to show the 'filters are applied' badge on the Filters
  //   action button of the wedding QAB, but the alternative is to lift
  //   mediaFilters state to the WeddingPage level, and I really don't
  //   like that idea.
  React.useEffect(() => {
    const badgeNode = document.querySelector(
      ".applied-media-filters-badge .MuiBadge-badge"
    );
    if (!badgeNode) return;

    badgeNode.classList.remove("MuiBadge-invisible", "hollow");

    if (nFiltersApplied === 0 && nFiltersSelected === 0) {
      badgeNode.classList.add("MuiBadge-invisible");
    } else if (nChangesToAppliedFilters > 0) {
      badgeNode.classList.add("hollow");
    } else {
      // show full badge
    }
  }, [nFiltersApplied, nFiltersSelected, nChangesToAppliedFilters]);

  return (
    <Box
      className="media-filters-container"
      sx={{ position: "relative", ...sx }}
    >
      <Tabs
        value={curTab}
        onChange={(e, v) => setCurTab(v)}
        sx={{
          marginBottom: "2rem",
          borderBottom: `1px solid ${theme.palette.primary.main}`,
          "& .MuiTabs-flexContainer": {
            display: "flex",
            gap: "2rem",
          },
          "& .MuiButtonBase-root.MuiTab-root": {
            minWidth: "unset",
            fontSize: "1rem",
            padding: 0,
            textTransform: "none",
          },
        }}
      >
        <Tab label="Apply filters" value="applied" />
        <Tab label="Saved filters" value="saved" />
      </Tabs>

      {curTab === "applied" ? (
        <Box
          className="applied-media-filters"
          sx={{
            marginBottom: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <PersonFilter
            wedding={wedding}
            mediaFilters={selectedMediaFilters}
            mediaFiltersDispatch={selectedMediaFiltersDispatch}
          />

          <NumPersonsFilter
            mediaFilters={selectedMediaFilters}
            mediaFiltersDispatch={selectedMediaFiltersDispatch}
          />

          <PublicPrivateFilter
            mediaFilters={selectedMediaFilters}
            mediaFiltersDispatch={selectedMediaFiltersDispatch}
          />

          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Button
              size="small"
              variant="contained"
              disabled={nChangesToAppliedFilters === 0}
              onClick={() => {
                appliedMediaFiltersDispatch({
                  type: "SET_FILTERS",
                  data: selectedMediaFilters,
                });
                enqueueSnackbar(`Filter applied`, {
                  variant: "info",
                  preventDuplicate: true,
                });
              }}
            >
              {nFiltersApplied > 0 && nChangesToAppliedFilters === 0
                ? "Applied"
                : "Apply"}
            </Button>

            {nFiltersApplied > 0 &&
            nChangesToAppliedFilters === 0 &&
            // if a guest receives a shared filter from a host, then saves
            //   it and sends the newly saved filter's link to someone else,
            //   they might encounter an unpleasant surprise: the final
            //   recipient can't see the photos that the guest was originally
            //   able to see, because the filter created by the guest only
            //   allows access to the photos visible to the guest
            // ideally, there would be some in-app info-tooltip explaining
            //   this to users, but I haven't figured out what to say, or even
            //   how to present it
            // so for now, I'm simply disabling the direct-saving of shared
            //   filters for everybody
            !selectedMediaFilters.savedFilterUuid ? (
              <Button
                variant="outlined"
                size="small"
                onClick={() => setSaveSelectedFiltersModalOpen(true)}
              >
                Save
              </Button>
            ) : null}

            {nChangesToAppliedFilters > 0 ? (
              <Button
                size="small"
                onClick={() => {
                  selectedMediaFiltersDispatch({
                    type: "SET_FILTERS",
                    data: appliedMediaFilters,
                  });
                }}
              >
                Discard changes
              </Button>
            ) : null}

            {selectedMediaFilters.savedFilterUuid || nFiltersApplied > 0 ? (
              <Button
                size="small"
                sx={{ minWidth: "auto", marginLeft: "auto", padding: 0 }}
                onClick={resetMediaFilters}
              >
                Clear
              </Button>
            ) : null}
          </Box>
        </Box>
      ) : (
        <Box
          className="saved-media-filters"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.75rem",
          }}
        >
          {savedFilters.map(({ id, uuid, name, creatorId, filters }) => {
            const isTheCurrentlySelectedFilter =
              selectedMediaFilters.savedFilterUuid === uuid;

            return (
              <Box
                key={id}
                className="saved-media-filter"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <Typography variant="body1" sx={{ color: "grey.dark" }}>
                  {name}
                </Typography>

                <Button
                  size="small"
                  sx={{
                    marginLeft: "auto",
                    padding: 0,
                    flexShrink: 0,
                  }}
                  onClick={
                    isTheCurrentlySelectedFilter
                      ? resetMediaFilters
                      : () => {
                          const action = {
                            type: "SET_FILTERS",
                            data: { ...filters, savedFilterUuid: uuid },
                          };
                          selectedMediaFiltersDispatch(action);
                          appliedMediaFiltersDispatch(action);
                          enqueueSnackbar(`Filter applied`, {
                            variant: "info",
                            preventDuplicate: true,
                          });
                        }
                  }
                >
                  {isTheCurrentlySelectedFilter ? "Clear" : "Apply"}
                </Button>

                <ReactionButton
                  size="small"
                  sx={{ width: "4.5rem", flexShrink: 0, padding: 0 }}
                  // sharing system filters causes problems for the receiver
                  // ex: 'photos-of-me' shared w. anon user results in
                  //   page crash when sharing link is opened, since
                  //   <featuring: 'me'> is not applicable to anon users
                  disabled={creatorId === 0}
                  onClick={() => {
                    const url = new URL(
                      [
                        "weddings",
                        routeMatch.params.weddingId,
                        routeMatch.params.tabName,
                        btoa(JSON.stringify({ savedFilterUuid: uuid })),
                      ].join("/"),
                      window.location.origin
                    );
                    navigator.clipboard.writeText(url.href);

                    // analytics
                    window.dataLayer.push({
                      event: "shareable_link_copied",
                    });
                  }}
                  onClickProps={{ children: "Copied!", disabled: true }}
                  clickTimeoutSeconds={1}
                >
                  Copy link
                </ReactionButton>
              </Box>
            );
          })}
        </Box>
      )}

      <SaveAppliedFiltersModal
        open={saveSelectedFiltersModalOpen}
        onClose={() => setSaveSelectedFiltersModalOpen(false)}
        weddingId={wedding.id}
        appliedFilters={appliedMediaFilters}
        onSave={(data) => {
          setSavedFilters((sfs) => sfs.concat(data));
          const action = {
            type: "UPDATE_FILTERS",
            data: { savedFilterUuid: data.uuid },
          };
          selectedMediaFiltersDispatch(action);
          appliedMediaFiltersDispatch(action);
          setSaveSelectedFiltersModalOpen(false);
        }}
      />
    </Box>
  );

  function resetMediaFilters() {
    selectedMediaFiltersDispatch({
      type: "RESET_FILTERS",
      data: { retainThese: ["mediaPath", "mediaType"] },
    });
    appliedMediaFiltersDispatch({
      type: "RESET_FILTERS",
      data: { retainThese: ["mediaPath", "mediaType"] },
    });
  }
}
