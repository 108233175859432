import { assign, createMachine } from "xstate";

export const createWeddingFlowFsmStatesOrdered = [
  "askBasicEventDetails",
  "waiting",
  "error",
  "created",
];

const createWeddingFlowFsm = createMachine(
  {
    // adding this because of a browser console warning
    // see https://xstate.js.org/docs/guides/actions.html#api
    predictableActionArguments: true,

    id: "createWeddingFlowFsm",
    context: { createWeddingData: null, createWeddingError: null },
    initial: "askBasicEventDetails",
    on: {
      RESET: {
        target: ".askBasicEventDetails",
        actions: ["reset"],
      },
    },
    states: {
      askBasicEventDetails: {
        on: {
          BASIC_EVENT_DETAILS_PROVIDED: {
            target: "waiting",
            actions: ["updateCreateWeddingData"],
          },
        },
      },
      waiting: {
        on: {
          ERROR_DURING_WEDDING_CREATION: {
            target: "error",
            actions: ["handleError"],
          },
          WEDDING_CREATED: { target: "created" },
        },
      },
      error: {
        on: {
          BACK_BUTTON_PRESSED: {
            target: "askBasicEventDetails",
          },
        },
      },
      created: {},
    },
  },
  {
    actions: {
      updateCreateWeddingData: assign({
        createWeddingData: (context, event) => ({
          ...context.createWeddingData,
          ...event.data,
        }),
      }),
      handleError: assign({
        createWeddingError: (context, event) => event.error,
      }),
      reset: assign({
        createWeddingData: null,
        createWeddingError: null,
      }),
    },
  }
);

export default createWeddingFlowFsm;
