import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_TARGET_ENV,
  integrations: [new BrowserTracing(), new Sentry.Replay()],

  // no need to send errors happening in dev to Sentry
  beforeSend: (event) =>
    process.env.REACT_APP_TARGET_ENV === "development" ? null : event,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate:
    process.env.REACT_APP_TARGET_ENV === "development" ? 0 : 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate:
    process.env.REACT_APP_TARGET_ENV === "development"
      ? 0
      : process.env.REACT_APP_TARGET_ENV === "staging"
      ? 1
      : 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

export default Sentry;
