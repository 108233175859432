import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import cx from "classnames";

import CustomModal from "@@components/common/CustomModal";
import SignupForm from "@@components/common/SignupForm";
import GoogleSignInButton from "./GoogleSignInButton";

export default function SignupModal({
  open,
  onClose,
  className,
  ...restProps
}) {
  return (
    <CustomModal
      className={cx("signup-modal", className)}
      open={open}
      onClose={onClose}
      {...restProps}
    >
      <Typography variant="body2" style={{ marginBottom: "1rem" }}>
        Signup with your email or phone number
      </Typography>

      <SignupForm />

      <Divider sx={{ margin: "1.5rem 0" }}>
        <Typography variant="body2">Or</Typography>
      </Divider>

      <GoogleSignInButton context="signup" sx={{ alignSelf: "center" }} />
    </CustomModal>
  );
}
