import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useSnackbar } from "notistack";

import CustomTextField from "@@components/common/CustomTextField";
import EventBusContext from "@@contexts/EventBusContext";
import {
  registerNewUser,
  sendVerificationCode,
  loginWithOTP,
} from "@@services/auth.service";
import EmailOrPhoneNumberField from "./InputFields/EmailOrPhoneNumberField";
import OtpField from "./InputFields/OtpField";

export default function SignUpForm({ sx }) {
  const eventBus = React.useContext(EventBusContext);
  const [otpSent, setOtpSent] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const formMethods = useForm({
    defaultValues: {
      name: "",
      emailOrPhoneNumber: "",
      verificationCode: "",
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
  } = formMethods;
  const { enqueueSnackbar } = useSnackbar();
  const otpFieldRef = React.useRef(null);

  return (
    <FormProvider {...formMethods}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ display: "flex", flexDirection: "column", ...sx }}
      >
        <CustomTextField
          label="Name *"
          error={errors.name}
          {...register("name", {
            required: { value: true, message: "This field is required" },
          })}
          size="normal"
          margin="normal"
        />

        <EmailOrPhoneNumberField
          required
          register={register}
          error={errors.emailOrPhoneNumber}
        />

        {otpSent ? (
          <OtpField
            required={otpSent}
            ref={otpFieldRef}
            ResendButtonProps={{
              onClick: () => {
                trySendingVerificationCode(getValues("emailOrPhoneNumber"));

                // analytics
                window.dataLayer.push({
                  event: "otp_requested",
                  otp_request_source: "signup_form_resend_button",
                });
              },
            }}
          />
        ) : null}

        <Box style={{ margin: "1rem 0 0 0", display: "flex", gap: "1rem" }}>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submitting}
            size="large"
          >
            {otpSent ? "Continue" : "Get OTP"}
          </LoadingButton>
        </Box>
      </Box>
    </FormProvider>
  );

  async function onSubmit({ name, emailOrPhoneNumber, verificationCode }) {
    const isPhoneNumber = !emailOrPhoneNumber.includes("@");
    if (isPhoneNumber && !emailOrPhoneNumber.startsWith("+91")) {
      setError("emailOrPhoneNumber", {
        type: "custom",
        message: `Sorry, we only support phone numbers from India at this time. Please use your email instead.`,
      });
      return;
    }

    setSubmitting(true);

    if (!verificationCode) {
      // analytics
      window.dataLayer.push({
        event: "otp_requested",
        otp_request_source: "signup_form",
      });

      await tryRegisteringNewUser(name, emailOrPhoneNumber);
    } else {
      try {
        const { data: user } = await loginWithOTP(
          emailOrPhoneNumber,
          verificationCode
        );
        eventBus.publish("login", { user, method: "signup_form" });
      } catch (e) {
        if (
          e.response?.status === 400 &&
          /Token is invalid/.test(e.response.data.error)
        ) {
          enqueueSnackbar("OTP is incorrect, or may have expired", {
            variant: "error",
          });
        } else {
          enqueueSnackbar(
            e.response?.data?.error ??
              "There was an error logging you in. Please try again later.",
            { variant: "error" }
          );
          throw e;
        }
      } finally {
        setSubmitting(false);
      }
    }
  }

  async function tryRegisteringNewUser(name, emailOrPhoneNumber) {
    const isPhoneNumber = emailOrPhoneNumber.startsWith("+");

    try {
      await registerNewUser(name, emailOrPhoneNumber);
    } catch (e) {
      if (e.response?.data?.error === "Account already exists") {
        setError("emailOrPhoneNumber", {
          type: "custom",
          message: `That ${
            isPhoneNumber ? "phone number" : "email"
          } is already registered.`,
        });
        return;
      } else {
        enqueueSnackbar(`An error occurred`, { variant: "error" });
        throw e;
      }
    } finally {
      setSubmitting(false);
    }

    setOtpSent(true);
    otpFieldRef.current.focus();
    enqueueSnackbar(
      isPhoneNumber
        ? "We've sent you an SMS with a one-time passcode."
        : "We've sent you an email with a one-time passcode. Please check your email inbox (or spam folder).",
      { variant: "info" }
    );
  }

  async function trySendingVerificationCode(emailOrPhoneNumber) {
    const isPhoneNumber = emailOrPhoneNumber.startsWith("+");

    try {
      await sendVerificationCode(emailOrPhoneNumber);
    } catch (e) {
      // do nothing; we'll act like it succeeded
      // the user can retry if they don't get the OTP
    }

    setOtpSent(true);
    otpFieldRef.current.focus();

    enqueueSnackbar(
      isPhoneNumber
        ? "We've sent you an SMS with a one-time passcode."
        : "We've sent you an email with a one-time passcode. Please check your email inbox (or spam folder).",
      { variant: "info" }
    );
    setSubmitting(false);
  }
}
