import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";

import CustomModal from "@@components/common/CustomModal";
import CustomTextField from "@@components/common/CustomTextField";
import {
  DropTargetFolderContext,
  SetDropTargetFolderContext,
} from "@@contexts/DropTargetFolderContexts";
import { CurrentWeddingFoldersDispatchContext } from "@@contexts/CurrentWeddingFoldersContexts";
import {
  WeddingMediaSelectedFiltersDispatchContext,
  WeddingMediaAppliedFiltersDispatchContext,
} from "@@contexts/WeddingMediaFiltersContexts";
import {
  deleteFolder,
  renameFolder,
} from "@@services/wedding-folder.service.js";
import { getHumanFriendlyFolderName } from "@@utils";
import folderImage from "@@img/folder300.png";

// ClickAwayListener needs its child to be able to hold a ref
const FolderNameForm = React.forwardRef(FolderNameForm_);

export default function WeddingFolder({ showOptionsMenu, folder }) {
  const dropTargetFolder = React.useContext(DropTargetFolderContext);
  const setDropTargetFolder = React.useContext(SetDropTargetFolderContext);
  const selectedMediaFiltersDispatch = React.useContext(
    WeddingMediaSelectedFiltersDispatchContext
  );
  const appliedMediaFiltersDispatch = React.useContext(
    WeddingMediaAppliedFiltersDispatchContext
  );
  const theme = useTheme();

  // grep DRAG_COUNTER_REF for explanation
  const dragCounterRef = React.useRef(0);

  const [nameEditMode, setNameEditMode] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const onCloseDeleteModal = (e) => setOpenDeleteModal(false);

  const { id, folderName } = folder;

  return (
    <Box
      className="wedding-folder"
      sx={{
        width: "8rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
        ...(dropTargetFolder?.id === id
          ? {
              backgroundColor: theme.palette.grey.extralight,
              border: `1px solid ${theme.palette.grey.light}`,
            }
          : {}),
      }}
      onClick={() => {
        const action = {
          type: "UPDATE_FILTERS",
          data: { mediaPath: folderName },
        };
        selectedMediaFiltersDispatch(action);
        appliedMediaFiltersDispatch(action);
      }}
      onDragEnter={(e) => {
        e.preventDefault();
        setDropTargetFolder(folder);
        dragCounterRef.current++;
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        if (--dragCounterRef.current === 0) {
          setDropTargetFolder((dtFolder) =>
            // when the user moves the mouse from one folder to another quickly,
            //   the onDragEnter handler of the second folder sometimes fires
            //   before the onDragLeave handler of the first one; we don't want
            //   to set dropTargetFolder to null when that happens
            dtFolder.id === id ? null : dtFolder
          );
        }
      }}
    >
      <Box
        component="img"
        src={folderImage}
        sx={{ width: "100%", marginBottom: "0.25rem" }}
      />

      {nameEditMode ? (
        <ClickAwayListener onClickAway={() => setNameEditMode(false)}>
          <FolderNameForm
            weddingFolder={folder}
            afterSubmit={() => setNameEditMode(false)}
          />
        </ClickAwayListener>
      ) : (
        <FolderNameAndOptions
          folder={folder}
          showOptionsMenu={showOptionsMenu}
          setNameEditMode={setNameEditMode}
          setOpenDeleteModal={setOpenDeleteModal}
        />
      )}

      <DeleteFolderModal
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        folder={folder}
      />
    </Box>
  );
}

function FolderNameForm_({ weddingFolder, afterSubmit }, ref) {
  const currentWeddingFoldersDispatch = React.useContext(
    CurrentWeddingFoldersDispatchContext
  );
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, register } = useForm({
    defaultValues: { folderName: weddingFolder.folderName.split("/").at(-1) },
  });

  return (
    <Box
      ref={ref}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ lineHeight: "normal" }}
    >
      <CustomTextField
        autoFocus
        inputProps={{ style: { textAlign: "center" } }}
        InputProps={{ sx: { height: "1.25rem", fontSize: "0.75rem" } }}
        {...register("folderName", { required: true })}
      />
    </Box>
  );

  async function onSubmit({ folderName }) {
    const oldName = weddingFolder.folderName.split("/").at(-1);
    if (oldName === folderName) return afterSubmit();

    currentWeddingFoldersDispatch({
      type: "RENAME",
      id: weddingFolder.id,
      folderName,
    });
    try {
      const { data } = await renameFolder(
        weddingFolder.weddingId,
        weddingFolder.id,
        folderName
      );
      afterSubmit(data);
    } catch (e) {
      currentWeddingFoldersDispatch({
        type: "RENAME",
        id: weddingFolder.id,
        folderName: oldName,
      });
      enqueueSnackbar(e.response?.data?.error ?? e.message, {
        variant: "error",
      });
    }
  }
}

function FolderNameAndOptions({
  folder,
  showOptionsMenu,
  setNameEditMode,
  setOpenDeleteModal,
}) {
  const theme = useTheme();

  const humanFriendlyFolderName = getHumanFriendlyFolderName(folder.folderName);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {showOptionsMenu ? (
        <Box
          className="counter-weight"
          sx={{ width: "1.5rem", flexShrink: 0 }}
        />
      ) : null}

      <Typography
        variant="caption"
        className="folder-name"
        sx={{
          flex: "1 0 0",
          alignSelf: "center",
          color: theme.palette.grey.dark,
          textAlign: "center",
        }}
      >
        {humanFriendlyFolderName}
      </Typography>

      {showOptionsMenu ? (
        <FolderOptionsMenu
          setNameEditMode={setNameEditMode}
          allowDelete={!folder.otherData.preventDelete}
          setOpenDeleteModal={setOpenDeleteModal}
        />
      ) : null}
    </Box>
  );
}

function FolderOptionsMenu({
  setNameEditMode,
  allowDelete,
  setOpenDeleteModal,
}) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <Box className="folder-menu" onClick={(e) => e.stopPropagation()}>
      <IconButton
        disableRipple
        sx={{ height: "100%", padding: "0.25rem" }}
        onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
      >
        <MoreVertIcon sx={{ width: "1rem", height: "1rem" }} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(e) => setAnchorEl(null)}
        MenuListProps={{
          dense: true,
          sx: { color: theme.palette.grey.dark },
        }}
      >
        <MenuItem
          onClick={(e) => {
            setNameEditMode(true);
            setAnchorEl(null);
          }}
        >
          <Typography variant="caption">Rename</Typography>
        </MenuItem>

        <MenuItem
          onClick={(e) => {
            if (!allowDelete) {
              enqueueSnackbar("This folder cannot be deleted", {
                variant: "error",
              });
            } else {
              setOpenDeleteModal(true);
            }
          }}
        >
          <Typography variant="caption">Delete</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}

function DeleteFolderModal({ open, onClose, folder }) {
  const { enqueueSnackbar } = useSnackbar();
  const currentWeddingFoldersDispatch = React.useContext(
    CurrentWeddingFoldersDispatchContext
  );

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      className="delete-folder-modal"
      BoxProps={{
        sx: { display: "flex", flexDirection: "column", gap: "1rem" },
      }}
    >
      <Typography component="h1" variant="h5">
        Are you sure?
      </Typography>

      <Typography>
        This will delete the folder and all photos/videos inside it
      </Typography>

      <Box sx={{ display: "flex", gap: "1rem", "& button": { width: "5rem" } }}>
        <Button
          variant="contained"
          onClick={async (e) => {
            currentWeddingFoldersDispatch({ type: "DELETE", id: folder.id });
            try {
              await deleteFolder(folder.weddingId, folder.id);
              onClose();
            } catch (e) {
              currentWeddingFoldersDispatch({
                type: "CREATE",
                folderData: folder,
              });
              enqueueSnackbar(e.response?.data?.error ?? e.message, {
                variant: "error",
              });
            }
          }}
        >
          Yes
        </Button>

        <Button variant="outlined" onClick={onClose}>
          No
        </Button>
      </Box>
    </CustomModal>
  );
}
