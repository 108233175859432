import cx from "classnames";
import React from "react";

import CustomTextField from "@@components/common/CustomTextField";
import { isValidEmail } from "@@utils/inputValidationUtils";

function EmailField({
  register,
  error,
  label,
  className,
  required,
  ...restProps
}) {
  return (
    <CustomTextField
      type="email"
      className={cx(className, "email-input")}
      label={label ?? `Email${required ? " *" : ""}`}
      error={!!error}
      helperText={error?.message ?? ""}
      {...register("email", {
        required: { value: required, message: "This field is required." },
        validate: isValidEmail,
      })}
      size="normal"
      margin="normal"
      {...restProps}
    />
  );
}

export default EmailField;
