import Box from "@mui/material/Box";
import cx from "classnames";
import React from "react";
import { useFormContext } from "react-hook-form";

import CustomTextField from "@@components/common/CustomTextField";
import ThrottledButton from "@@components/common/Buttons/ThrottledButton";

const OtpField = React.forwardRef(OtpField_);

function OtpField_(
  { required, TextFieldProps, ResendButtonProps, className, sx, ...restProps },
  ref
) {
  const { errors, register } = useFormContext();

  return (
    <Box
      className={className}
      sx={{ display: "flex", alignItems: "flex-start", gap: "1rem", ...sx }}
      {...restProps}
    >
      <CustomTextField
        inputRef={ref}
        className={cx(className, "otp-input")}
        label={`OTP${required ? " *" : ""}`}
        error={!!errors?.verificationCode}
        helperText={errors?.verificationCode?.message ?? ""}
        {...register("verificationCode", {
          required: {
            value: required,
            message: "This field is required.",
          },
          pattern: {
            value: /^\d+$/,
            message: "Not a valid OTP.",
          },
        })}
        size="normal"
        margin="normal"
        {...TextFieldProps}
      />

      <ThrottledButton
        disableRipple
        startThrottled
        throttleTimeSeconds={20}
        sx={{ position: "relative", top: "1.5rem", flex: "1 0 auto" }}
        {...ResendButtonProps}
      >
        Resend
      </ThrottledButton>
    </Box>
  );
}

export default OtpField;
