import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useSnackbar } from "notistack";

import EmailOrPhoneNumberField from "@@components/common/InputFields/EmailOrPhoneNumberField";
import OtpField from "@@components/common/InputFields/OtpField";
import EventBusContext from "@@contexts/EventBusContext";
import { sendVerificationCode, loginWithOTP } from "@@services/auth.service";

export default function LoginForm() {
  const eventBus = React.useContext(EventBusContext);
  const [otpSent, setOtpSent] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const formMethods = useForm({
    defaultValues: {
      emailOrPhoneNumber: "",
      verificationCode: "",
    },
  });
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = formMethods;
  const { enqueueSnackbar } = useSnackbar();
  const otpFieldRef = React.useRef(null);

  return (
    <FormProvider {...formMethods}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <EmailOrPhoneNumberField
          required
          register={register}
          error={errors.emailOrPhoneNumber}
        />

        {otpSent ? (
          <OtpField
            required={otpSent}
            ref={otpFieldRef}
            ResendButtonProps={{
              onClick: () => {
                trySendingVerificationCode(getValues("emailOrPhoneNumber"));

                // analytics
                window.dataLayer.push({
                  event: "otp_requested",
                  otp_request_source: "login_form_resend_button",
                });
              },
            }}
          />
        ) : null}

        <Box style={{ margin: "1rem 0 0 0", display: "flex", gap: "1rem" }}>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submitting}
            size="large"
          >
            {otpSent ? "Continue" : "Get OTP"}
          </LoadingButton>
        </Box>
      </Box>
    </FormProvider>
  );

  async function onSubmit({ emailOrPhoneNumber, verificationCode }) {
    const isPhoneNumber = !emailOrPhoneNumber.includes("@");
    if (isPhoneNumber && !emailOrPhoneNumber.startsWith("+91")) {
      setError("emailOrPhoneNumber", {
        type: "custom",
        message: `Sorry, we only support phone numbers from India at this time. Please use your email instead.`,
      });
      return;
    }

    setSubmitting(true);

    if (!verificationCode) {
      // analytics
      window.dataLayer.push({
        event: "otp_requested",
        otp_request_source: "login_form",
      });

      await trySendingVerificationCode(emailOrPhoneNumber);
    } else {
      try {
        const { data: user } = await loginWithOTP(
          emailOrPhoneNumber,
          verificationCode
        );
        eventBus.publish("login", { user, method: "login_form" });
      } catch (e) {
        if (
          e.response?.status === 400 &&
          /Token is invalid/.test(e.response.data.error)
        ) {
          enqueueSnackbar("OTP is incorrect, or may have expired", {
            variant: "error",
          });
        } else {
          enqueueSnackbar(
            e.response?.data?.error ??
              "There was an error logging you in. Please try again later.",
            { variant: "error" }
          );
          throw e;
        }
      } finally {
        setSubmitting(false);
      }
    }
  }

  async function trySendingVerificationCode(emailOrPhoneNumber) {
    try {
      await sendVerificationCode(emailOrPhoneNumber);
    } catch (e) {
      if (e.response?.data?.error === "Account not found") {
        setError("emailOrPhoneNumber", {
          type: "custom",
          message: "Account not found. Please sign up first.",
        });
        setSubmitting(false);
        return;
      }

      // do nothing; we'll act like it succeeded
      // the user can retry if they don't get the OTP
    }

    setOtpSent(true);
    otpFieldRef.current.focus();

    const isPhoneNumber = emailOrPhoneNumber.startsWith("+");
    enqueueSnackbar(
      isPhoneNumber
        ? "We've sent you an SMS with a one-time passcode."
        : "We've sent you an email with a one-time passcode. Please check your email inbox (or spam folder).",
      { variant: "info" }
    );
    setSubmitting(false);
  }
}
