import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { useForm } from "react-hook-form";

import PhoneNumberField from "@@components/common/InputFields/PhoneNumberField";
import EmailField from "@@components/common/InputFields/EmailField";
import { updateUser } from "@@services/fotobot-api.service";
import { getUserFullName } from "@@utils";

export default function UserProfileForm({
  user,
  onUserUpdated,
  submitButtonText,
  sx,
  ...restProps
}) {
  const [state, setState] = React.useState({
    loading: false,
    message: "",
    error: "",
  });
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    defaultValues: {
      name: getUserFullName(user),
      email: user.email ?? "",
      phoneNumber: user.phoneNumber ?? "",
    },
  });

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onProfileFormSubmit)}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "1.5rem",
        ...sx,
      }}
      {...restProps}
    >
      <TextField
        className="name-field"
        autoComplete="off"
        name="name"
        label="Name *"
        fullWidth
        error={!!errors.name}
        helperText={errors.name?.message ?? ""}
        sx={{ margin: 0 }}
        {...register("name", {
          required: { value: true, message: "This field is required." },
        })}
      />

      {user.email ? (
        <EmailField
          disabled
          register={register}
          error={errors.email}
          sx={{ margin: 0 }}
        />
      ) : null}

      {user.phoneNumber ? (
        <PhoneNumberField
          disabled
          register={register}
          error={errors.phoneNumber}
          sx={{ margin: 0 }}
        />
      ) : null}

      <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <LoadingButton
          loading={state.loading}
          type="submit"
          variant="outlined"
          size="large"
          sx={{ flexShrink: 0 }}
        >
          {submitButtonText || "Update"}
        </LoadingButton>

        <Typography component="p" variant="body2" sx={{ color: "#8d8d8d" }}>
          {state.message || state.error}
        </Typography>
      </Box>
    </Box>
  );

  async function onProfileFormSubmit(data) {
    setState({ loading: true, message: "", error: "" });

    try {
      let [firstName, ...lastName] = data.name.split(" ");
      lastName = lastName.join(" ");

      const { data: updatedUser } = await updateUser(user.id, {
        firstName,
        lastName,
      });
      setState({ loading: false, message: "Update successful", error: "" });
      setTimeout(() => setState((s) => ({ ...s, message: "" })), 2000);
      onUserUpdated(updatedUser);
    } catch (e) {
      setState({
        loading: false,
        message: "",
        error: `Update failed: ${e.response?.data?.error ?? e.message}`,
      });
    }
  }
}
