import React from "react";

export const WeddingMediaDisplayPrefsContext = React.createContext(null);
WeddingMediaDisplayPrefsContext.displayName = "WeddingMediaDisplayPrefsContext";

export const SetWeddingMediaDisplayPrefsContext = React.createContext(() => {});
SetWeddingMediaDisplayPrefsContext.displayName =
  "SetWeddingMediaDisplayPrefsContext";

export function WeddingMediaDisplayPrefsProvider({ children }) {
  const [mediaDisplayPrefs, setMediaDisplayPrefs] = React.useState({
    showFolders: false,
  });

  return (
    <SetWeddingMediaDisplayPrefsContext.Provider value={setMediaDisplayPrefs}>
      <WeddingMediaDisplayPrefsContext.Provider value={mediaDisplayPrefs}>
        {children}
      </WeddingMediaDisplayPrefsContext.Provider>
    </SetWeddingMediaDisplayPrefsContext.Provider>
  );
}
