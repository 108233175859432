import _ from "lodash";
import { weddingCoverPhotoDesiredWidthPx } from "@@config";
import { fotobotApiAxiosInstance as axiosInstance } from "@@utils/axiosInstances";
import { resizeImageFile } from "@@utils/webApiUtils";

export async function sayHello() {
  const { data } = await axiosInstance.get(`/`);
  return data;
}

export async function getUser(userId = "me") {
  const { data } = await axiosInstance.get(`/users/${userId}`);
  return data;
}

export async function updateUser(userId = "me", payload) {
  const { data } = await axiosInstance.post(`/users/${userId}`, payload);
  return data;
}

export async function updateUserPic(userId = "me", file, type) {
  const formData = new FormData();
  formData.append(type, file);

  const { data } = await axiosInstance.post(
    `/users/${userId}/${type}`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );

  return data;
}

export async function getUserWeddings(userId = "me") {
  const { data } = await axiosInstance.get(`/users/${userId}/weddings`);
  return data;
}

export async function searchWeddings(searchString) {
  const { data } = await axiosInstance.get(`/weddings`, {
    params: { searchString },
  });
  return data;
}

export async function getWedding(weddingId) {
  const { data } = await axiosInstance.get(`/weddings/${weddingId}`);
  return data;
}

export async function addUsersToWedding(weddingId, payload) {
  if (payload instanceof File) {
    if (payload.type !== "text/csv") {
      throw new Error(`Must upload a csv`);
    }

    const formData = new FormData();
    formData.append("invitees", payload);
    const { data } = await axiosInstance.post(
      `/weddings/${weddingId}/users/addFromCsv`,
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );

    return data;
  } else {
    const { data } = await axiosInstance.post(
      `/weddings/${weddingId}/users`,
      payload
    );

    return data;
  }
}

export async function setWeddingCoverPhoto(
  weddingId,
  coverPhotoFile,
  opts = { resize: true }
) {
  const formData = new FormData();
  formData.append(
    "coverPhoto",
    opts.resize
      ? await resizeImageFile(coverPhotoFile, weddingCoverPhotoDesiredWidthPx)
      : coverPhotoFile
  );

  const { data } = await axiosInstance.post(
    `/weddings/${weddingId}/coverPhoto`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );

  return data;
}

export async function toggleLikeForWeddingMedia(weddingId, mediaId) {
  const { data } = await axiosInstance.post(
    `/weddings/${weddingId}/media/${mediaId}/toggleLike`
  );
  return data;
}

export async function createWedding(payload) {
  const { data } = await axiosInstance.post(`/weddings`, payload);
  return data;
}

export async function updateWedding(weddingId, { coverPhoto, ...restPayload }) {
  const { data } = await axiosInstance.patch(
    `/weddings/${weddingId}`,
    restPayload
  );

  if (coverPhoto) {
    const { data: coverPhotoUrl } = await setWeddingCoverPhoto(
      weddingId,
      coverPhoto
    );
    data.data.coverPhotoUrl = coverPhotoUrl;
  }

  return data;
}

export async function updateUserWeddingSettings(
  userId = "me",
  weddingId,
  payload
) {
  const { data } = await axiosInstance.post(
    `/users/${userId}/weddings/${weddingId}`,
    payload
  );
  return data;
}

export async function listWeddingUsers(weddingId, filters) {
  const { data } = await axiosInstance.get(`/weddings/${weddingId}/users`, {
    params: _.pickBy(filters, _.isNil),
  });
  return data;
}

export async function listPeopleAtWedding(weddingId) {
  const { data } = await axiosInstance.get(`/weddings/${weddingId}/people`);
  return data;
}

export async function updateWeddingUser(weddingId, userId, payload) {
  const { data } = await axiosInstance.patch(
    `/weddings/${weddingId}/users/${userId}`,
    payload
  );
  return data;
}

export async function createWeddingEvent(weddingId, payload) {
  const { data } = await axiosInstance.post(
    `/weddings/${weddingId}/weddingEvents`,
    payload
  );
  return data;
}

export async function editWeddingEvent(weddingId, weddingEventId, payload) {
  const { data } = await axiosInstance.patch(
    `/weddings/${weddingId}/weddingEvents/${weddingEventId}`,
    payload
  );
  return data;
}

export async function deleteWeddingEvent(weddingId, weddingEventId) {
  const { data } = await axiosInstance.delete(
    `/weddings/${weddingId}/weddingEvents/${weddingEventId}`
  );
  return data;
}

export async function resetFlagsForWeddingEvent(weddingId, weddingEventId) {
  const { data } = await axiosInstance.patch(
    `/weddings/${weddingId}/weddingEvents/${weddingEventId}/resetNudgeSentFlags`
  );
  return data;
}

export async function createWeddingUpgradeOrder(weddingId) {
  const { data } = await axiosInstance.post(`/weddings/${weddingId}/orders`);
  return data;
}

export async function fulfillWeddingUpgradeOrder(weddingId, orderId, payload) {
  const { data } = await axiosInstance.post(
    `/weddings/${weddingId}/orders/${orderId}`,
    { ...payload, action: "fulfill" }
  );
  return data;
}

export async function searchVendors(params) {
  const { data } = await axiosInstance.get(`/vendors`, { params });
  return data;
}

export async function getWeddingVendors(weddingId) {
  const { data } = await axiosInstance.get(`/weddings/${weddingId}/vendor`);
  return data;
}

export async function addWeddingVendor(weddingId, payload) {
  const { data } = await axiosInstance.post(
    `/weddings/${weddingId}/vendor`,
    payload
  );
  return data;
}

export async function removeWeddingVendor(weddingId, vawId) {
  const { data } = await axiosInstance.delete(
    `/weddings/${weddingId}/vendor/${vawId}`
  );
  return data;
}

export async function editWeddingVendorDetails(weddingId, vawId, payload) {
  const { data } = await axiosInstance.patch(
    `/weddings/${weddingId}/vendor/${vawId}`,
    payload
  );
  return data;
}

export async function changeVendorProfilePic(vendorId, file) {
  const formData = new FormData();
  formData.append("profilePic", file);

  const { data } = await axiosInstance.post(
    `/vendors/${vendorId}/profilePic`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );

  return data;
}
