import Box from "@mui/material/Box";
import _ from "lodash";
import React from "react";
import { useSnackbar } from "notistack";

import AddGuestsModal from "./AddGuestsModal";
import WeddingGuestCard from "./WeddingGuestCard";
import CardGrid from "@@components/common/CardGrid";
import { PRIVILEGED_WEDDING_ROLES, weddingRoleSortOrder } from "@@config";
import { CurrentWeddingContext } from "@@contexts/CurrentWeddingContexts";
import {
  CurrentWeddingUsersContext,
  SetCurrentWeddingUsersContext,
} from "@@contexts/CurrentWeddingUsersContexts";
import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import {
  WeddingPageAddGuestsModalOpenContext,
  WeddingPageModalsStateSettersContext,
} from "@@contexts/WeddingPageLevelModalContexts";
import {
  listWeddingUsers,
  updateWeddingUser,
} from "@@services/fotobot-api.service";
import { altStrCmp, getUserFullName } from "@@utils";

export default function WeddingGuests({ onError }) {
  const wedding = React.useContext(CurrentWeddingContext);
  const weddingUsers = React.useContext(CurrentWeddingUsersContext);
  const setWeddingUsers = React.useContext(SetCurrentWeddingUsersContext);
  const loggedInUser = React.useContext(LoggedInUserContext);
  const addGuestsModalOpen = React.useContext(
    WeddingPageAddGuestsModalOpenContext
  );
  const { setAddGuestsModalOpen } = React.useContext(
    WeddingPageModalsStateSettersContext
  );
  const { enqueueSnackbar } = useSnackbar();

  const [weddingUsersSortOrder, setWeddingUsersSortOrder] =
    React.useState(null);

  const getDisplayNameForUser = React.useCallback(
    (user) =>
      getUserFullName(user) ||
      (user.referrer?.id === loggedInUser?.id
        ? user.nameAccordingToReferrer
        : ""),
    [loggedInUser]
  );

  React.useEffect(() => {
    if (weddingUsers.length > 0) return;

    (async () => {
      try {
        const { data: weddingUsers } = await listWeddingUsers(wedding.id);
        setWeddingUsers(weddingUsers);
      } catch (e) {
        e.message = e.response.data.error ?? e.message;

        if (onError) onError(e);
        else {
          enqueueSnackbar(`Error fetching guest list: ${e.message}`, {
            variant: "error",
          });
        }
      }
    })();
  }, [weddingUsers, wedding, enqueueSnackbar, setWeddingUsers, onError]);

  React.useEffect(() => {
    if (weddingUsers.length === 0 || weddingUsersSortOrder) return;

    setWeddingUsersSortOrder(
      weddingUsers
        // need to make a copy of weddingUsers to avoid "TypeError: 0 is read-only"
        // if we weren't already filtering, we'd make a .concat() call here
        .filter((wu) => wu.role !== "ADMIN")
        .sort(
          (a, b) =>
            weddingRoleSortOrder[a.role] - weddingRoleSortOrder[b.role] ||
            altStrCmp(getDisplayNameForUser(a), getDisplayNameForUser(b)) ||
            a.id - b.id
        )
        .map(({ id }) => id)
    );
  }, [weddingUsers, weddingUsersSortOrder, getDisplayNameForUser]);

  const weddingUsersById = _.keyBy(weddingUsers, "id");
  const weddingUsersInSortedOrder = weddingUsersSortOrder
    ? weddingUsersSortOrder.map((id) => weddingUsersById[id])
    : [];

  return (
    <Box className="wedding-guests-container" sx={{ margin: "0 1rem" }}>
      <CardGrid className="wedding-guests">
        {weddingUsersInSortedOrder.map((wu) => (
          <WeddingGuestCard
            key={wu.id}
            weddingGuest={wu}
            switchWeddingRoleForUser={
              PRIVILEGED_WEDDING_ROLES.includes(wedding.role)
                ? switchWeddingRoleForUser
                : null
            }
            sx={{ justifySelf: "center" }}
          />
        ))}
      </CardGrid>

      <AddGuestsModal
        open={addGuestsModalOpen}
        onClose={() => setAddGuestsModalOpen(false)}
      />
    </Box>
  );

  async function switchWeddingRoleForUser(userId) {
    const idxOfUserToUpdate = weddingUsers.findIndex(({ id }) => id === userId);
    const userToUpdate = weddingUsers[idxOfUserToUpdate];
    const newRole =
      userToUpdate.role === "INVITEE"
        ? "UNINVITED"
        : userToUpdate.role === "UNINVITED"
        ? "CLIENT_OTHER"
        : "INVITEE";

    const userAfterUpdate = { ...userToUpdate, role: newRole };

    const weddingUsersBeforeUpdate = weddingUsers;
    const weddingUsersAfterUpdate = weddingUsers.concat([]);
    weddingUsersAfterUpdate.splice(idxOfUserToUpdate, 1, userAfterUpdate);
    setWeddingUsers(weddingUsersAfterUpdate);

    try {
      await updateWeddingUser(wedding.id, userId, { role: newRole });
    } catch (e) {
      setWeddingUsers(weddingUsersBeforeUpdate);
      enqueueSnackbar(
        `Failed to update role for ${getUserFullName(userToUpdate)}`,
        { variant: "error" }
      );
    }
  }
}
