import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

export default function useWeddingMediaFiltersFromUrl() {
  const history = useHistory();
  const routeMatch = useRouteMatch(
    "/weddings/:weddingId/:tabName?/:b64Filters?"
  );
  const { weddingId, tabName, b64Filters } = routeMatch.params;

  React.useEffect(() => {
    if (b64Filters) {
      history.replace(`/weddings/${weddingId}/${tabName}`);
    }
  }, [history, weddingId, tabName, b64Filters]);

  if (!["photos", "videos"].includes(tabName)) {
    return {};
  }

  const filtersFromUrl = {
    mediaType: routeMatch.params.tabName === "videos" ? "VIDEO" : "IMAGE",
  };
  if (b64Filters) {
    Object.assign(filtersFromUrl, JSON.parse(atob(b64Filters)));
  }

  return filtersFromUrl;
}
