import BurstModeIcon from "@mui/icons-material/BurstMode";
import LockIcon from "@mui/icons-material/Lock";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import cx from "classnames";
import React from "react";
import { Link } from "react-router-dom";

import LoginPageFooter from "@@components/common/LoginPageFooter";
import { TopLevelModalsOpenStateSettersContext } from "@@contexts/TopLevelModalsWrapper";

import logo from "@@img/logo64.png";
import imgHome from "@@img/loginPage/home.jpg";
import { Typography } from "@mui/material";

export default function LoginPageDesktop({ className, ...restProps }) {
  const { setLoginModalOpen, setSignupModalOpen } = React.useContext(
    TopLevelModalsOpenStateSettersContext
  );
  const [activeSellingPoint, setActiveSellingPoint] = React.useState(null);

  const denseMode = useMediaQuery("(max-height: 799px)");

  return (
    <Box
      className={cx(
        "page-container login-page-container flex-center-content cover-parent",
        className
      )}
      {...restProps}
    >
      <Box
        className="content-area"
        sx={{
          width: "90%",
          maxWidth: "1400px",
          height: "90%",
          maxHeight: "900px",
          padding: "1rem",
        }}
      >
        <Box
          component="header"
          sx={{
            marginBottom: "1rem",
            height: "3rem",
            padding: "0 1rem",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <Box
            component="img"
            src={logo}
            alt="Fotobot logo"
            sx={{ width: "3rem" }}
          />

          <Button
            variant="contained"
            size="large"
            disableElevation
            sx={{ marginLeft: "auto" }}
            onClick={() => setSignupModalOpen(true)}
          >
            Signup
          </Button>

          <Button
            variant="outlined"
            size="large"
            onClick={() => setLoginModalOpen(true)}
          >
            Login
          </Button>
        </Box>

        <Box
          component="main"
          sx={{
            height: "calc(100% - 4rem)",
            borderRadius: "50px",
            background: `left top / cover url(${imgHome})`,
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box className="hero" sx={{ flex: "1 0 0" }}>
            <Box
              sx={{
                maxWidth: { xs: "100%", lg: "50%" },
                backgroundColor: "#FAFAFACA",
                borderRadius: "50px",
                padding: denseMode ? "2.5rem 3rem" : "3rem",
                display: "flex",
                flexDirection: "column",
                gap: "1.25rem",
                "& h1": {
                  fontSize: denseMode ? "2.5rem" : "3.75rem",
                },
                "& span.highlight": {
                  color: "primary.main",
                },
                "& p": {
                  fontSize: "1.25rem",
                },
                "& p span.gigantify": {
                  fontFamily: "serif",
                  fontSize: denseMode ? "2.5rem" : "3.75rem",
                  lineHeight: denseMode ? "2.5rem" : "3.75rem",
                  whiteSpace: "pre-line",
                },
              }}
            >
              {activeSellingPoint === null ? (
                <>
                  <Typography
                    component="h1"
                    variant="h2"
                    sx={{ fontFamily: "serif", lineHeight: 1 }}
                  >
                    Share your wedding photos
                  </Typography>
                  <Typography>
                    Your guests deserve it.{" "}
                    <span className="highlight">Fotobot</span> helps you do it
                    the way you want.
                  </Typography>

                  <Box sx={{ display: "flex", gap: "1rem" }}>
                    <Button
                      variant="contained"
                      size="large"
                      sx={{ width: "12rem", height: "3rem" }}
                      onClick={() => setSignupModalOpen(true)}
                    >
                      Signup
                    </Button>

                    <Link to="/weddings/demo-wedding">
                      <Button
                        variant="outlined"
                        size="large"
                        sx={{ width: "16rem", height: "3rem" }}
                        onClick={() => {}}
                      >
                        Try the demo
                      </Button>
                    </Link>
                  </Box>
                </>
              ) : activeSellingPoint === "privateByDefault" ? (
                <>
                  <Typography variant="body1">
                    <span className="gigantify">W</span>hen you add photos to
                    your{" "}
                    <span className="highlight">
                      wedding gallery on Fotobot
                    </span>{" "}
                    and share it with guests, they can access the photos they
                    are in by uploading a selfie
                  </Typography>

                  <Typography variant="body1">
                    All other photos are hidden until <em>you</em> decide to
                    make them available
                  </Typography>
                </>
              ) : activeSellingPoint === "easyFilterEasyShare" ? (
                <>
                  <Typography variant="body1">
                    <span className="gigantify">S</span>
                    ometimes, you want to find and share photos of groups of
                    people, and asking them to individually upload selfies just
                    won't do
                  </Typography>

                  <Typography variant="body1">
                    Fotobot's{" "}
                    <span className="highlight">facial recognition system</span>{" "}
                    and{" "}
                    <span className="highlight">multi-person filtering</span>{" "}
                    features will help you find and share photos of groups of
                    people with minimal hassle
                  </Typography>
                </>
              ) : activeSellingPoint === "saveGuestUploads" ? (
                <>
                  <Typography variant="body1">
                    <span className="gigantify">Y</span>
                    our friends and guests may have captured great moments your
                    official photographer missed
                  </Typography>

                  <Typography variant="body1">
                    Fotobot sends them gentle{" "}
                    <Box component="span" sx={{ color: "primary.main" }}>
                      whatsapp reminders
                    </Box>{" "}
                    inviting them to contribute to your gallery, so you don't
                    have to
                  </Typography>
                </>
              ) : null}
            </Box>
          </Box>

          <Box
            className="selling-points"
            sx={{
              marginTop: "auto",
              display: "flex",
              justifyContent: { xs: "space-between", lg: "flex-end" },
              gap: "1rem",
              "& .selling-point": {
                flex: "1 0 0",
                maxWidth: "15rem",
                height: "200px",
                backgroundColor: "#FAFAFAAA",
                backdropFilter: "blur(10px)",
                borderRadius: "50px",
                padding: "1rem",
                ":hover": {
                  backgroundColor: "transparent",
                  color: "grey.extralight",
                  cursor: "pointer",
                },
                "& svg": {
                  position: "relative",
                  top: "3rem",
                  margin: "0 auto",
                  display: "flex",
                  fontSize: "3rem",
                },
                "& h2": {
                  position: "relative",
                  top: "3.5rem",
                  fontSize: "1rem",
                  fontWeight: 500,
                  padding: "0 1rem",
                  textAlign: "center",
                },
              },
            }}
          >
            <Box
              className={cx(
                "selling-point",
                activeSellingPoint === "privateByDefault" ? "active" : ""
              )}
              onMouseOver={() => setActiveSellingPoint("privateByDefault")}
              onMouseOut={() => setActiveSellingPoint(null)}
            >
              <LockIcon />
              <Typography variant="h2">Private by default</Typography>
            </Box>

            <Box
              className={cx(
                "selling-point",
                activeSellingPoint === "easyFilterEasyShare" ? "active" : ""
              )}
              onMouseOver={() => setActiveSellingPoint("easyFilterEasyShare")}
              onMouseOut={() => setActiveSellingPoint(null)}
            >
              <ReduceCapacityIcon />
              <Typography variant="h2">Easy-filter, Easy-share</Typography>
            </Box>

            <Box
              className={cx(
                "selling-point",
                activeSellingPoint === "saveGuestUploads" ? "active" : ""
              )}
              onMouseOver={() => setActiveSellingPoint("saveGuestUploads")}
              onMouseOut={() => setActiveSellingPoint(null)}
            >
              <BurstModeIcon
                sx={{
                  top: "2.5rem !important",
                  fontSize: "4.25rem !important",
                }}
              />
              <Typography variant="h2" sx={{ top: "2.25rem !important" }}>
                Also save moments you <em>didn't</em> pay for
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        component={LoginPageFooter}
        sx={{ position: "absolute", top: "100%", width: "100%" }}
      />
    </Box>
  );
}
