import cx from "classnames";
import React from "react";

import CustomTextField from "@@components/common/CustomTextField";
import { isValidPhone } from "@@utils/inputValidationUtils";

export default function PhoneNumberField({
  register,
  error,
  required,
  label,
  className,
  ...restProps
}) {
  return (
    <CustomTextField
      className={cx(className, "phone-number-input")}
      label={label ?? `Phone Number${required ? " *" : ""}`}
      error={!!error}
      helperText={error?.message ?? ""}
      {...register("phoneNumber", {
        required: { value: required, message: "This field is required." },
        setValueAs: (v) => (v.startsWith("+") ? v : `+91${v}`),
        validate: isValidPhone,
      })}
      size="normal"
      margin="normal"
      placeholder="Enter ph. no. with country code"
      {...restProps}
    />
  );
}
