import Box from "@mui/material/Box";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import React from "react";

function Carousel({
  elems,
  initialSelectedElemIdx,
  renderElem,
  onChange,
  loop,
  sx,
  className,
  ...restProps
}) {
  const carouselRef = React.useRef(null);
  const touchStartCoords = React.useRef({});
  const renderedElemRef = React.useRef(null);

  const [curElemIdx, setCurElemIdx] = React.useState(initialSelectedElemIdx);

  const showPrev = React.useCallback(
    () =>
      setCurElemIdx((idx) =>
        loop ? (idx === 0 ? elems.length - 1 : idx - 1) : Math.max(idx - 1, 0)
      ),
    [elems, loop]
  );
  const showNext = React.useCallback(
    async () =>
      setCurElemIdx((idx) =>
        loop ? (idx + 1) % elems.length : Math.min(idx + 1, elems.length - 1)
      ),
    [elems, loop]
  );

  React.useEffect(() => {
    if (onChange) onChange(elems[curElemIdx]);
  }, [curElemIdx, elems, onChange]);

  // ensures keyboard nav will work from the moment of first render
  React.useEffect(() => {
    carouselRef.current.focus();
  }, []);

  // allow user to browse carousel with left/right arrow keys
  React.useEffect(() => {
    const cur = carouselRef.current;
    cur.addEventListener("keydown", keydownHandler);
    return () => cur.removeEventListener("keydown", keydownHandler);

    function keydownHandler({ key }) {
      if (key === "ArrowLeft") {
        showPrev();
      } else if (key === "ArrowRight") {
        showNext();
      } else {
        // do nothing
      }
    }
  }, [showNext, showPrev]);

  // allow user to browse carousel by swiping
  React.useEffect(() => {
    const cur = carouselRef.current;
    cur.addEventListener("touchstart", touchStartHandler);
    cur.addEventListener("touchend", touchEndHandler);

    return () => {
      cur.removeEventListener("touchstart", touchStartHandler);
      cur.removeEventListener("touchend", touchEndHandler);
    };

    function touchStartHandler(e) {
      if (e.touches.length === 1) {
        touchStartCoords.current.x = e.changedTouches[0].screenX;
        touchStartCoords.current.y = e.changedTouches[0].screenY;
      } else {
        // > 1 touch indicates user is trying to zoom in/out,
        //   not swipe to the next photo
        delete touchStartCoords.current.x;
        delete touchStartCoords.current.y;
      }
    }

    function touchEndHandler(e) {
      const { x: touchStartX } = touchStartCoords.current;
      if (!touchStartX) return;

      const { screenX: touchEndX } = e.changedTouches[0];

      const minDistance = 50;

      if (touchStartX - touchEndX <= -minDistance) {
        // left->right swipe
        showPrev();
      } else if (touchStartX - touchEndX >= minDistance) {
        // right->left swipe
        showNext();
      } else {
        // do nothing
      }
    }
  }, [showNext, showPrev]);

  return (
    <Box
      ref={carouselRef}
      className="carousel"
      // allows elem to be focused, which allows keydown events
      //   to be registered
      tabIndex="0"
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& .carousel-browse-button": {
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 2,
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          color: "grey.light",
          margin: "0.5rem",
          padding: 0,
          "&:hover": { color: "white" },
          "&:disabled": { backgroundColor: "transparent", color: "grey.main" },
        },
        ...sx,
      }}
      {...restProps}
    >
      {renderElem(elems[curElemIdx], {}, renderedElemRef)}

      <IconButton
        disableRipple
        className="carousel-browse-button"
        onClick={(e) => {
          e.stopPropagation();
          showPrev();
        }}
        disabled={curElemIdx === 0}
        sx={{ left: 0 }}
      >
        <ChevronLeftIcon fontSize="large" />
      </IconButton>

      <IconButton
        disableRipple
        className="carousel-browse-button"
        onClick={(e) => {
          e.stopPropagation();
          showNext();
        }}
        disabled={curElemIdx === elems.length - 1}
        sx={{ right: 0 }}
      >
        <ChevronRightIcon fontSize="large" />
      </IconButton>
    </Box>
  );
}

export default Carousel;
