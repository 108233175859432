import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React from "react";
import { QRCode } from "react-qrcode-logo";

import { getWeddingLink } from "@@utils/siteUtils";

export default function ShareGalleryQRCodeContainer({
  wedding,
  sx,
  ...restProps
}) {
  const [showQR, setShowQR] = React.useState(false);

  return (
    <Box
      className="share-gallery-qr-code flex-center-content"
      sx={{ flexDirection: "column", ...sx }}
      {...restProps}
    >
      {showQR ? (
        <Box
          component={QRCode}
          className="qr-code"
          value={getWeddingLink(wedding)}
          size={130}
          quietZone={10}
        />
      ) : (
        <Box
          className="flex-center-content"
          // size should match size of qr code + quiet zone to prevent
          //   modal resizing when QR code toggled
          sx={{ width: "150px", height: "150px" }}
        >
          <Button size="small" onClick={() => setShowQR(true)}>
            Show QR
          </Button>
        </Box>
      )}

      <Typography
        variant="caption"
        sx={{ visibility: showQR ? "visible" : "hidden" }}
      >
        passcode: {wedding.passcode}
      </Typography>
    </Box>
  );
}
