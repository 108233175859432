import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import BottomBar from "@@components/common/BottomBar";
import UploadProgress from "@@components/common/UploadProgress";
import {
  CurrentWeddingContext,
  SetCurrentWeddingContext,
  CurrentWeddingMediaUploadInputsContext,
  ChangeWeddingCoverPhotoInputRefContext,
} from "@@contexts/CurrentWeddingContexts";
import CurrentWeddingFoldersContextsWrapper from "@@contexts/CurrentWeddingFoldersContexts";
import {
  CurrentWeddingUsersContext,
  SetCurrentWeddingUsersContext,
} from "@@contexts/CurrentWeddingUsersContexts";
import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import {
  WeddingMediaSavedFiltersContext,
  SetWeddingMediaSavedFiltersContext,
} from "@@contexts/WeddingMediaFiltersContexts";
import WeddingPageLevelModalContextsWrapper from "@@contexts/WeddingPageLevelModalContexts";
import { getWedding } from "@@services/fotobot-api.service";
import { getSavedMediaFilters } from "@@services/wedding-media.service";
import { savedFiltersCmp } from "@@utils/mediaFiltersUtils";
import AddMediaModal from "./AddMediaModal";
import ChangeWeddingCoverPhotoInput from "./ChangeWeddingCoverPhotoInput";
import WeddingMediaDropzone from "./WeddingMediaDropzone";
import WeddingMediaUploadInput from "./WeddingMediaUploadInput";
import WeddingPhotos from "./WeddingPhotos/WeddingPhotos";
import WeddingPageShell from "./WeddingPageShell";
import WeddingSettingsPane from "./WeddingSettingsPane";
// import WeddingVendors from "./WeddingVendors/WeddingVendors";
import WeddingVideos from "./WeddingVideos/WeddingVideos";

import WeddingPageUnauthorized from "./WeddingPageUnauthorized/WeddingPageUnauthorized";
import WeddingGuests from "./WeddingGuests/WeddingGuests";

export default function Wedding({ sx, ...restProps }) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const loggedInUser = React.useContext(LoggedInUserContext);

  const routeMatch = useRouteMatch("/weddings/:weddingIdOrSlug/:tabName?");
  const { weddingIdOrSlug, tabName } = routeMatch.params;

  const allMediaUploadInputRef = React.useRef(null);
  const photoUploadInputRef = React.useRef(null);
  const videoUploadInputRef = React.useRef(null);
  const mediaUploadInputRefs = React.useMemo(
    () => ({
      allMediaUploadInputRef,
      photoUploadInputRef,
      videoUploadInputRef,
    }),
    []
  );

  const changeWeddingCoverPhotoInputRef = React.useRef(null);

  const [allowedToView, setAllowedToView] = React.useState(true);

  const [wedding, setWedding] = React.useState(null);
  const [weddingUsers, setWeddingUsers] = React.useState([]);
  const [savedMediaFilters, setSavedMediaFilters] = React.useState([]);

  const fetchWeddingData = React.useCallback(async () => {
    // if not logged in, wedding.role and a few other fields will
    //   be unset
    try {
      const { data: wedding } = await getWedding(weddingIdOrSlug);
      setWedding(wedding);
      setAllowedToView(true);
    } catch (e) {
      enqueueSnackbar(
        `An error occurred: ${e.response?.data?.error ?? e.message}`,
        { variant: "error" }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser, weddingIdOrSlug, enqueueSnackbar]);

  React.useEffect(() => {
    fetchWeddingData();
  }, [fetchWeddingData]);

  // if url has wedding id, silently replace with slug
  React.useEffect(() => {
    if (wedding && weddingIdOrSlug !== wedding.slug) {
      history.replace(`/weddings/${wedding.slug}`);
    }
  }, [history, weddingIdOrSlug, wedding]);

  React.useEffect(() => {
    if (!wedding?.id) return;

    (async () => {
      const { data: retrievedFilters } = await getSavedMediaFilters(wedding.id);
      setSavedMediaFilters(
        retrievedFilters.sort((a, b) => savedFiltersCmp(a, b, loggedInUser))
      );
    })();
  }, [
    // getSavedMediaFilters' response changes depending on login status
    loggedInUser,
    wedding?.id,
  ]);

  if (!wedding) {
    // alternatively, we could show a splash screen until wedding data is
    //   retrieved
    return null;
  }

  return (
    <CurrentWeddingContext.Provider value={wedding}>
      <SetCurrentWeddingContext.Provider value={setWedding}>
        <CurrentWeddingFoldersContextsWrapper wedding={wedding}>
          <CurrentWeddingUsersContext.Provider value={weddingUsers}>
            <SetCurrentWeddingUsersContext.Provider value={setWeddingUsers}>
              <SetWeddingMediaSavedFiltersContext.Provider
                value={setSavedMediaFilters}
              >
                <WeddingMediaSavedFiltersContext.Provider
                  value={savedMediaFilters}
                >
                  <CurrentWeddingMediaUploadInputsContext.Provider
                    value={mediaUploadInputRefs}
                  >
                    <ChangeWeddingCoverPhotoInputRefContext.Provider
                      value={changeWeddingCoverPhotoInputRef}
                    >
                      <WeddingPageLevelModalContextsWrapper>
                        <Box
                          className="wedding-container"
                          sx={{
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            [theme.breakpoints.up("md")]: {
                              marginLeft: "4rem",
                              marginTop: "1rem",
                            },
                            ...sx,
                          }}
                          {...restProps}
                        >
                          <WeddingPageShell allowedToView={allowedToView} />

                          <Box
                            className="wedding-page-main-content"
                            sx={{
                              zIndex: 0,
                              flexGrow: 1,
                              // allows to clear the quick-action bar at the bottom
                              //   on mobile/tab devices
                              marginBottom: { xs: "4.5rem", md: 0 },
                            }}
                          >
                            {!allowedToView ? (
                              <WeddingPageUnauthorized
                                weddingId={wedding.id}
                                onAuthSuccess={() => fetchWeddingData()}
                              />
                            ) : tabName === "people" ? (
                              <WeddingGuests onError={handleError} />
                            ) : tabName === "photos" ? (
                              <WeddingMediaDropzone>
                                <WeddingPhotos onError={handleError} />
                              </WeddingMediaDropzone>
                            ) : tabName === "videos" ? (
                              <WeddingMediaDropzone>
                                <WeddingVideos onError={handleError} />
                              </WeddingMediaDropzone>
                            ) : null}
                          </Box>

                          <WeddingSettingsPane
                            wedding={wedding}
                            setWedding={setWedding}
                          />

                          <BottomBar>
                            {/* WARNING: cannot pass wedding slug to
                            UploadProgress; it needs the actual id */}
                            <UploadProgress weddingId={wedding.id} />
                          </BottomBar>

                          <AddMediaModal />

                          <WeddingMediaUploadInput
                            weddingId={wedding.id}
                            ref={allMediaUploadInputRef}
                          />
                          <WeddingMediaUploadInput
                            weddingId={wedding.id}
                            ref={photoUploadInputRef}
                            accept="image/*"
                          />
                          <WeddingMediaUploadInput
                            weddingId={wedding.id}
                            ref={videoUploadInputRef}
                            accept="video/*"
                          />

                          <ChangeWeddingCoverPhotoInput
                            weddingId={wedding.id}
                            setWedding={setWedding}
                            ref={changeWeddingCoverPhotoInputRef}
                          />
                        </Box>
                      </WeddingPageLevelModalContextsWrapper>
                    </ChangeWeddingCoverPhotoInputRefContext.Provider>
                  </CurrentWeddingMediaUploadInputsContext.Provider>
                </WeddingMediaSavedFiltersContext.Provider>
              </SetWeddingMediaSavedFiltersContext.Provider>
            </SetCurrentWeddingUsersContext.Provider>
          </CurrentWeddingUsersContext.Provider>
        </CurrentWeddingFoldersContextsWrapper>
      </SetCurrentWeddingContext.Provider>
    </CurrentWeddingContext.Provider>
  );

  function handleError(e) {
    if (
      ["Not authenticated", "Not authorized"].some((x) => e.message.includes(x))
    ) {
      setAllowedToView(false);
    } else {
      enqueueSnackbar(
        `There was an error: ${e.response?.data?.error ?? e.message}`,
        { variant: "error" }
      );
    }
  }
}
