import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import GoogleSignInButton from "./GoogleSignInButton";
import LoginForm from "./LoginForm";

export default function LoginComponent({ headline, restProps }) {
  return (
    <Box
      className="login"
      sx={{ display: "flex", flexDirection: "column" }}
      {...restProps}
    >
      {headline ? (
        <Typography variant="body2" style={{ marginBottom: "1rem" }}>
          {headline}
        </Typography>
      ) : null}

      <LoginForm />

      <Divider sx={{ margin: "1.5rem 0" }}>
        <Typography variant="body2">Or</Typography>
      </Divider>

      <GoogleSignInButton context="login" sx={{ alignSelf: "center" }} />
    </Box>
  );
}

LoginComponent.defaultProps = {
  headline: "",
  onSuccess: () => {},
};
