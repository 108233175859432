import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React from "react";

import CustomModal from "@@components/common/CustomModal";
import { CurrentWeddingContext } from "@@contexts/CurrentWeddingContexts";
import ShareGalleryMessageContainer from "./ShareGalleryMessageContainer";
import ShareGalleryQRCodeContainer from "./ShareGalleryQRCodeContainer";

export default function AddGuestsModal({ open, onClose, ...restProps }) {
  const wedding = React.useContext(CurrentWeddingContext);

  return (
    <CustomModal open={open} onClose={onClose} {...restProps}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Typography component="h1" variant="h5">
          Share this gallery
        </Typography>

        <ShareGalleryMessageContainer wedding={wedding} />

        <Divider>
          <Typography variant="body2">Or</Typography>
        </Divider>

        <ShareGalleryQRCodeContainer wedding={wedding} />
      </Box>
    </CustomModal>
  );
}
