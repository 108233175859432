import BurstModeIcon from "@mui/icons-material/BurstMode";
import LockIcon from "@mui/icons-material/Lock";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import cx from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import PrimaryButton from "@@components/common/Buttons/PrimaryButton";
import LoginPageFooter from "@@components/common/LoginPageFooter";
import { TopLevelModalsOpenStateSettersContext } from "@@contexts/TopLevelModalsWrapper";

import "swiper/css";
import "swiper/css/pagination";

import logo from "@@img/logo64.png";
import imgHome from "@@img/loginPage/home.jpg";

export default function LoginPageMobile({ className, sx, ...restProps }) {
  const theme = useTheme();
  const { setLoginModalOpen, setSignupModalOpen } = React.useContext(
    TopLevelModalsOpenStateSettersContext
  );

  return (
    <Box
      className={cx(
        "page-container page-container-mobile login-page-container",
        className
      )}
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        background: `no-repeat 30% / cover url(${imgHome})`,
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
      {...restProps}
    >
      <Box
        className="header"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1rem 1rem 0 1rem",
        }}
      >
        <Box
          component="img"
          src={logo}
          alt="Fotobot logo"
          sx={{ width: "3rem" }}
        />

        <Button
          size="large"
          variant="outlined"
          onClick={() => {
            setLoginModalOpen(true);

            // analytics
            window.dataLayer.push({ event: "login_button_clicked" });
          }}
        >
          Log in
        </Button>
      </Box>

      <Box
        component={Swiper}
        className="features"
        pagination={true}
        loop={true}
        modules={[Pagination]}
        onSlideChange={() => {
          // analytics
          window.dataLayer.push({ event: "login_page_slide_change" });
        }}
        sx={{
          width: "100%",
          // ensure it's exactly the right height for the content
          //   to look vertically centered wrt the viewport, despite
          //   there being a header
          height: "calc(100% - 8rem)",
          "& .swiper-slide.fotobot-feature": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          "& .swiper-pagination.swiper-pagination-horizontal": {
            // position is already absolute
            left: "50%",
            transform: "translateX(-50%)",
            width: "auto",
            padding: "0.25rem 1.5rem",
            backgroundColor: "#45454545",
            borderRadius: "1.25rem",
          },
          "& .swiper-pagination-bullet": {
            backgroundColor: "white !important",
          },
          "& .swiper-pagination-bullet.swiper-pagination-bullet-active": {
            backgroundColor: `${theme.palette.primary.main} !important`,
          },
        }}
      >
        <SwiperSlide className="fotobot-feature">
          <SellingPoint>
            <Typography component="h1" variant="h4">
              <Box
                component="span"
                sx={{
                  color: "primary.main",
                  textShadow: `0px 0px 2px ${theme.palette.primary.light}`,
                }}
              >
                Share your wedding photos.
              </Box>{" "}
              Your guests deserve it.
            </Typography>

            <Typography variant="body1">
              Fotobot's facial-recognition-empowered gallery helps you do it the
              way you want
            </Typography>

            <PrimaryButton
              onClick={() => {
                setSignupModalOpen(true);

                // analytics
                window.dataLayer.push({ event: "signup_button_clicked" });
              }}
            >
              Sign up
            </PrimaryButton>

            <Link to="/weddings/demo-wedding">
              <PrimaryButton variant="outlined" fullWidth>
                Try the demo
              </PrimaryButton>
            </Link>
          </SellingPoint>
        </SwiperSlide>

        <SwiperSlide className="fotobot-feature">
          <SellingPoint>
            <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
              <LockIcon
                sx={{
                  position: "relative",
                  top: "-2px",
                  color: "primary.main",
                }}
              />
              <Typography variant="h6">
                <Box component="span" sx={{ color: "primary.main" }}>
                  Private
                </Box>{" "}
                by default
              </Typography>
            </Box>

            <Typography variant="body1">
              When you add photos to your Fotobot gallery and share it with
              guests, they can access the photos they are in by uploading a
              selfie
            </Typography>

            <Typography variant="body1">
              All other photos are hidden until{" "}
              <Box
                component="span"
                sx={{ fontStyle: "italic", fontWeight: "bold" }}
              >
                you
              </Box>{" "}
              decide to make them available
            </Typography>
          </SellingPoint>
        </SwiperSlide>

        <SwiperSlide className="fotobot-feature">
          <SellingPoint>
            <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
              <ReduceCapacityIcon sx={{ color: "primary.main" }} />
              <Typography variant="h6">Easy-filter, Easy-share</Typography>
            </Box>

            <Typography variant="body1">
              Sometimes, you want to find and share photos of groups of people,
              and asking them to individually upload selfies just won't do
            </Typography>

            <Typography variant="body1">
              Fotobot's{" "}
              <Box component="span" sx={{ color: "primary.main" }}>
                facial recognition system
              </Box>{" "}
              and multi-person filtering will help you find and share photos of
              groups of people in seconds
            </Typography>
          </SellingPoint>
        </SwiperSlide>

        <SwiperSlide className="fotobot-feature">
          <SellingPoint>
            <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
              <BurstModeIcon
                sx={{ color: "primary.main", fontSize: "1.75rem" }}
              />
              <Typography variant="h6">Every moment, saved</Typography>
            </Box>

            <Typography variant="body1">
              Your friends and guests may have captured great moments your
              official photographer missed
            </Typography>

            <Typography variant="body1">
              Fotobot sends them gentle{" "}
              <Box component="span" sx={{ color: "primary.main" }}>
                whatsapp reminders
              </Box>{" "}
              inviting them to contribute to your gallery, so you don't have to
            </Typography>
          </SellingPoint>
        </SwiperSlide>
      </Box>

      <Box
        component={LoginPageFooter}
        sx={{ position: "absolute", top: "100%", width: "100%" }}
      />
    </Box>
  );
}

function SellingPoint({ children, className, sx, ...restProps }) {
  return (
    <Box
      className={cx("selling-point", className)}
      sx={{
        boxSizing: "border-box",
        margin: "0 auto",
        maxWidth: "20rem",
        backgroundColor: "#FAFAFAAA",
        backdropFilter: "blur(10px)",
        borderRadius: "24px",
        padding: "2rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        ...sx,
      }}
      {...restProps}
    >
      {children}
    </Box>
  );
}
