import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import MovieIcon from "@mui/icons-material/Movie";

import PrimaryButton from "@@components/common/Buttons/PrimaryButton";
import { PRIVILEGED_WEDDING_ROLES } from "@@config";
import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import { TopLevelModalsOpenStateSettersContext } from "@@contexts/TopLevelModalsWrapper";
import {
  CurrentWeddingContext,
  CurrentWeddingMediaUploadInputsContext,
} from "@@contexts/CurrentWeddingContexts";
import { CurrentWeddingFoldersContext } from "@@contexts/CurrentWeddingFoldersContexts";
import { WeddingPageModalsStateSettersContext } from "@@contexts/WeddingPageLevelModalContexts";
import { WeddingMediaDisplayPrefsContext } from "@@contexts/WeddingMediaDisplayPrefs";
import { countFilters } from "@@utils/mediaFiltersUtils";

export default function NoMediaCTA({ mediaFilters, sx, ...restProps }) {
  const loggedInUser = React.useContext(LoggedInUserContext);
  const wedding = React.useContext(CurrentWeddingContext);
  const weddingFolders = React.useContext(CurrentWeddingFoldersContext);
  const mediaDisplayPrefs = React.useContext(WeddingMediaDisplayPrefsContext);
  const { setGetUserSelfieModalOpen, setLoginModalOpen, setSignupModalOpen } =
    React.useContext(TopLevelModalsOpenStateSettersContext);
  const { setAddMediaModalOpen } = React.useContext(
    WeddingPageModalsStateSettersContext
  );
  const { photoUploadInputRef, videoUploadInputRef, allMediaUploadInputRef } =
    React.useContext(CurrentWeddingMediaUploadInputsContext);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const mediaType =
    mediaFilters.mediaType === "IMAGE"
      ? "photos"
      : mediaFilters.mediaType === "VIDEO"
      ? "videos"
      : "media";
  const viewportIsMdPlus = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      className="wedding-media-container flex-center-content"
      sx={{
        maxWidth: { xs: "90%", sm: "30rem" },
        margin: "0 auto",
        color: "grey.main",
        flexDirection: "column",
        gap: "1rem",
        "& p": { textAlign: "center" },
        ...sx,
      }}
      {...restProps}
    >
      {getCta()}
    </Box>
  );

  function handleAddMediaActionButtonClick() {
    const mediaUploadIsAllowed =
      wedding.anonymousUploadsAreAllowed || wedding.role;

    if (mediaUploadIsAllowed) {
      if (viewportIsMdPlus && !localStorage.getItem("hideAddMediaHints")) {
        setAddMediaModalOpen(true);
      } else {
        (mediaType === "photos"
          ? photoUploadInputRef
          : mediaType === "videos"
          ? videoUploadInputRef
          : allMediaUploadInputRef
        ).current.click();
      }
    } else {
      enqueueSnackbar(`You must log in to upload ${mediaType}`, {
        variant: "info",
      });
    }
  }

  function getCta() {
    const userHasAppliedFilters = countFilters(mediaFilters) > 0;
    const userHasAppliedFeaturingFilterOnSelf =
      mediaFilters.featuring.data.some(({ id }) =>
        [loggedInUser?.id, "me"].filter(_.identity).includes(id)
      );
    const userIsLoggedIn = !!loggedInUser;
    const userHasUploadedSelfie = !!loggedInUser?.selfiePicUrl;
    const { showFolders: userIsViewingFolders } = mediaDisplayPrefs;
    const galleryAllowsAnonymousUploads = wedding.anonymousUploadsAreAllowed;
    const galleryIsEmpty =
      wedding[mediaType === "videos" ? "nVideos" : "nPhotos"] === 0;
    const userIsWeddingHost = PRIVILEGED_WEDDING_ROLES.includes(wedding.role);
    const userIsWeddingGuest = !userIsWeddingHost && !!wedding.role;
    const userCanAddMediaToGallery =
      galleryAllowsAnonymousUploads ||
      (userIsLoggedIn && (userIsWeddingHost || userIsWeddingGuest));

    const addMediaCta = (
      <PrimaryButton
        startIcon={mediaType === "videos" ? <MovieIcon /> : <AddAPhotoIcon />}
        onClick={() => handleAddMediaActionButtonClick()}
      >
        Add {mediaType}
      </PrimaryButton>
    );
    const addSelfieCta = (
      <PrimaryButton onClick={() => setGetUserSelfieModalOpen(true)}>
        Add selfie
      </PrimaryButton>
    );
    const loginCta = (
      <PrimaryButton onClick={() => setLoginModalOpen(true)}>
        Log in
      </PrimaryButton>
    );
    const signupCta = (
      <PrimaryButton
        variant="outlined"
        onClick={() => setSignupModalOpen(true)}
      >
        Sign up
      </PrimaryButton>
    );
    const loginOrSignupBox = (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        {loginCta}
        {signupCta}
      </Box>
    );

    if (userHasAppliedFeaturingFilterOnSelf) {
      // LOGIC:
      // not logged in => log in and add selfie
      // logged in + no selfie => add selfie
      // logged in + just added selfie => please wait
      // logged in + added selfie a while ago => add photos

      if (!userIsLoggedIn) {
        return (
          <>
            <Typography>
              You must log in (or sign up) and upload your selfie before we can
              identify your {mediaType}.
            </Typography>
            {loginOrSignupBox}
          </>
        );
      }

      if (!userHasUploadedSelfie) {
        return (
          <>
            <Typography>
              To access the {mediaType} you are in, please add a selfie so our
              facial recognition system can recognize you in them.
            </Typography>

            {addSelfieCta}
          </>
        );
      }

      return (
        <Typography>
          Sorry, there appear to be no {mediaType} of you in this{" "}
          {userIsViewingFolders ? "folder" : "gallery"} ...
        </Typography>
      );
    }

    if (userHasAppliedFilters) {
      return (
        <Typography>
          Sorry, there's no media in this{" "}
          {userIsViewingFolders ? "folder" : "gallery"} that matches the filters
          you've applied ...
        </Typography>
      );
    }

    if (galleryIsEmpty) {
      return (
        <>
          <Typography>
            There are no {mediaType} in this{" "}
            {userIsViewingFolders ? "folder" : "gallery"} ...{" "}
            {userCanAddMediaToGallery
              ? `Add some?`
              : !userIsLoggedIn
              ? `To add some, please log in (or sign up).`
              : ""}
          </Typography>

          {userCanAddMediaToGallery
            ? addMediaCta
            : !userIsLoggedIn
            ? loginOrSignupBox
            : null}
        </>
      );
    }

    if (userIsViewingFolders) {
      const folderInView = weddingFolders.find(
        (f) => f.folderName === mediaFilters.mediaPath
      );
      if (!folderInView) {
        // user is at top-level, or
        //   folders have not yet been retrieved from backend
        return null;
      }
      if (folderInView[mediaType === "videos" ? "nVideos" : "nPhotos"] === 0) {
        return <Typography>This folder is empty ...</Typography>;
      } else {
        <Typography>
          Sorry, it appears the hosts have not yet made any {mediaType} in this
          folder available to view ...
        </Typography>;
      }
    }

    // if no media can be shown even though the gallery is not empty, it means
    // - the user is not a host, AND
    // - no media has been made public by hosts, OR
    //     user has been marked "uninvited" and can't see even public stuff

    return (
      <Typography>
        Sorry, it appears the hosts have not yet made any {mediaType} available
        to view ...
      </Typography>
    );
  }
}
