import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React from "react";

import LoginComponent from "@@components/common/LoginComponent";
import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import WeddingPasscodeForm from "./WeddingPasscodeForm";

export default function WeddingPageUnauthorized({ weddingId, onAuthSuccess }) {
  const loggedInUser = React.useContext(LoggedInUserContext);

  return (
    <Box
      className="wedding-container wedding-page-unauthorized"
      sx={{
        height: "100%",
        maxWidth: "30rem",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Paper
        variant="outlined"
        elevation={0}
        className="login-form-container"
        style={{ margin: "0 1rem", padding: "2rem" }}
      >
        {loggedInUser ? (
          <>
            <Typography variant="body2" sx={{ marginBottom: "1rem" }}>
              Please enter the passcode to access this wedding
            </Typography>
            <WeddingPasscodeForm
              weddingId={weddingId}
              onSuccess={onAuthSuccess}
            />
          </>
        ) : (
          <LoginComponent headline="Please log in to access this wedding" />
        )}
      </Paper>
    </Box>
  );
}
