export default class EventBus {
  constructor() {
    this.subscribers = {};
  }

  subscribe(eventType, handler) {
    this.subscribers[eventType] ??= [];
    this.subscribers[eventType].push(handler);
  }

  unsubscribe(eventType, handler) {
    this.subscribers[eventType] = (this.subscribers[eventType] ?? []).filter(
      (h) => h !== handler
    );
  }

  publish(eventType, payload) {
    const subscribers = this.subscribers[eventType] ?? [];
    subscribers.forEach((handler) => handler(payload));
  }
}
