import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PsychologyAltOutlinedIcon from "@mui/icons-material/PsychologyAltOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import cx from "classnames";
import dayjs from "dayjs";
import React from "react";

import { PRIVILEGED_WEDDING_ROLES } from "@@config";
import { CurrentWeddingContext } from "@@contexts/CurrentWeddingContexts";
import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import WeddingGuestRoleControl from "./WeddingGuestRoleControl";
import { getUserFirstNameAndInitial, getUserFullName } from "@@utils";
import { capitalizeFirstLetter } from "@@utils/strUtils";

export default function WeddingGuestCard({
  weddingGuest,
  switchWeddingRoleForUser,
  className,
  sx,
  ...restProps
}) {
  const loggedInUser = React.useContext(LoggedInUserContext);
  const wedding = React.useContext(CurrentWeddingContext);
  const loggedInUserIsHost = PRIVILEGED_WEDDING_ROLES.includes(wedding.role);

  const guestName =
    getUserFullName(weddingGuest) ||
    weddingGuest.nameAccordingToReferrer ||
    "___";

  return (
    <Card
      variant="outlined"
      className={cx("wedding-guest", className)}
      sx={{
        width: "100%",
        maxWidth: "25rem",
        backgroundColor: "grey.extralight",
        ...sx,
      }}
      {...restProps}
    >
      <CardContent
        sx={{
          padding: "1rem 1rem 0 1rem",
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Avatar
          alt={guestName}
          src={weddingGuest.profilePicUrl}
          sx={{ width: "5rem", height: "5rem" }}
        />

        <Box
          className="guest-details"
          sx={{
            "& .guest-info": {
              margin: "0.5rem 0",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              "& svg": {
                fontSize: "1.25rem",
                color: "grey.main",
              },
              "& p": {
                color: "grey.main",
              },
            },
          }}
        >
          <Typography
            component="h1"
            variant="body1"
            sx={{ fontWeight: "bold" }}
          >
            {guestName}
          </Typography>

          <Typography
            variant="body2"
            sx={{ marginBottom: "1rem", color: "grey.main" }}
          >
            {weddingGuest.role === "CLIENT_OTHER"
              ? "Host"
              : ["INVITEE", "UNINVITED"].includes(weddingGuest.role)
              ? "Guest"
              : capitalizeFirstLetter(weddingGuest.role.toLowerCase())}
          </Typography>

          <Box className="guest-info">
            <AccessTimeIcon />
            <Typography variant="body2">
              Added on {dayjs(weddingGuest.timeAddedToWedding).format("D MMM")}
            </Typography>
          </Box>

          {weddingGuest.referrer ? (
            <Box className="guest-info">
              <PsychologyAltOutlinedIcon sx={{ transform: "scale(1.2)" }} />
              <Typography variant="body2">
                Added by {getUserFirstNameAndInitial(weddingGuest.referrer)}
              </Typography>
            </Box>
          ) : null}

          {loggedInUser?.admin ? (
            <Box className="guest-info">
              {weddingGuest.selfiePicUploaded ? <CheckIcon /> : <CloseIcon />}
              <Typography variant="body2">
                {weddingGuest.selfiePicUploaded
                  ? "Selfie uploaded"
                  : "Selfie not uploaded"}
              </Typography>
            </Box>
          ) : null}
        </Box>
      </CardContent>

      <CardActions
        sx={{
          padding: "0.5rem 1rem 1rem 1rem",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {loggedInUserIsHost ? (
          <WeddingGuestRoleControl
            weddingGuest={weddingGuest}
            switchWeddingRoleForUser={switchWeddingRoleForUser}
            sx={{
              width: "10rem",
              justifyContent: { xs: "flex-start", sm: "center" },
            }}
          />
        ) : null}
      </CardActions>
    </Card>
  );
}
