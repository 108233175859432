import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React from "react";

import CustomTextField from "@@components/common/CustomTextField";
import PaneHeading from "@@components/common/Headings/PaneHeading";
import { searchWeddings } from "@@services/fotobot-api.service";
import { combineRanges } from "@@utils";
import { findIndexesAtWhichSubstringFoundInString } from "@@utils/strUtils";

export default function FindWeddingComponent({ onSelect }) {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = React.useState(false);
  const [weddings, setWeddings] = React.useState([]);
  const [numSearchResults, setNumSearchResults] = React.useState(0);

  const debouncedHandleInputChange = _.debounce(handleInputChange, 400);
  const autocompleteOptions =
    numSearchResults <= weddings.length
      ? weddings
      : weddings.concat({
          pretty: `+ ${numSearchResults - weddings.length} more`,
        });

  return (
    <Box className="find-wedding-container">
      <PaneHeading
        variant="body2"
        sx={{ marginBottom: "1rem", color: "grey.main" }}
      >
        Find a wedding on Fotobot
      </PaneHeading>

      <Autocomplete
        id="find-wedding-autocomplete"
        clearOnBlur={false}
        handleHomeEndKeys={false}
        options={autocompleteOptions}
        filterOptions={(x) => x}
        getOptionDisabled={(option) => !option.id}
        getOptionLabel={(option) => option.pretty}
        renderOption={(props, option, state) => {
          const { pretty: label } = option;
          const labelLowercased = label.toLowerCase();
          const searchTokens = state.inputValue
            .split(" ")
            .filter((x) => x)
            .map((x) => x.toLowerCase());

          const rangesToHighlight = combineRanges(
            ..._.flatten(
              searchTokens.map((token) =>
                findIndexesAtWhichSubstringFoundInString(
                  labelLowercased,
                  token
                ).map((idx) => [idx, idx + token.length - 1])
              )
            )
          );

          const labelParts = rangesToHighlight
            .reduce((acc, range, idx) => {
              const start = idx === 0 ? 0 : rangesToHighlight[idx - 1][1] + 1;
              return acc.concat([
                {
                  inRange: false,
                  key: `${start}-${range[0]}`,
                  text: label.slice(start, range[0]),
                },
                {
                  inRange: true,
                  key: `${range[0]}-${range[1] + 1}`,
                  text: label.slice(range[0], range[1] + 1),
                },
              ]);
            }, [])
            .concat(
              rangesToHighlight.length > 0
                ? {
                    inRange: false,
                    key: `${rangesToHighlight.at(-1)[1] + 1}-${
                      label.length - 1
                    }`,
                    text: label.slice(rangesToHighlight.at(-1)[1] + 1),
                  }
                : {
                    inRange: false,
                    key: `0-${label.length - 1}`,
                    text: label,
                  }
            );

          return (
            <li {...props}>
              {labelParts.map(({ key, inRange, text }) => (
                <Box
                  component="span"
                  key={key}
                  sx={{
                    backgroundColor: inRange ? "lightyellow" : "transparent",
                    borderBottom: inRange
                      ? "2px solid yellow"
                      : "2px solid transparent",
                    whiteSpace: "pre",
                  }}
                >
                  {text}
                </Box>
              ))}
            </li>
          );
        }}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            autoFocus
            label="Search"
            helperText="Try typing the name of the bride, or the groom"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        onInputChange={debouncedHandleInputChange}
        onChange={(e, selectedWedding) => onSelect(selectedWedding)}
        loading={loading}
      />
    </Box>
  );

  async function handleInputChange(e, newInputValue) {
    if (newInputValue.length < 3) {
      setWeddings([]);
      setNumSearchResults(0);
      return;
    }

    setLoading(true);
    try {
      const { data: foundWeddings, nResults } = await searchWeddings(
        newInputValue
      );
      setWeddings(foundWeddings);
      setNumSearchResults(nResults);
    } catch (e) {
      enqueueSnackbar(`An error occurred`, { variant: "error" });
      throw e;
    } finally {
      setLoading(false);
    }
  }
}
