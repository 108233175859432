import ShareIcon from "@mui/icons-material/Share";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import React from "react";

import ReactionButton from "@@components/common/Buttons/ReactionButton";
import { getWeddingLink } from "@@utils/siteUtils";

export default function ShareGalleryMessageContainer({ wedding }) {
  const { enqueueSnackbar } = useSnackbar();
  const shareGalleryMessageRef = React.useRef(null);

  const showShareButton = !!navigator.canShare;

  return (
    <Box className="share-gallery-message-container">
      <Box
        className="share-gallery-message"
        ref={shareGalleryMessageRef}
        sx={{
          marginBottom: "0.75rem",
          borderRadius: "10px",
          padding: "1rem",
          backgroundColor: "grey.extralight",
          overflowWrap: "break-word",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="body2">
          Hi! Here's where you can find your photos from {wedding.name}:{" "}
          {getWeddingLink(wedding)}
        </Typography>

        <Typography variant="body2">
          When prompted, the passcode is {wedding.passcode}
        </Typography>

        <Typography variant="body2">
          Don't forget to add any photos you have to the gallery :)
        </Typography>
      </Box>

      <Box className="share-buttons">
        {showShareButton ? (
          <Button
            size="small"
            startIcon={<ShareIcon sx={{ fontSize: "0.75rem !important" }} />}
            sx={{
              minWidth: "auto",
              padding: "0 1rem",
              "& .MuiButton-startIcon": {
                marginLeft: "0 !important",
                marginRight: "0.25rem !important",
              },
            }}
            onClick={async () => {
              await navigator.share({
                url: getWeddingLink(wedding),
                text: shareGalleryMessageRef.current.innerText,
              });
            }}
          >
            Share
          </Button>
        ) : null}

        <ReactionButton
          size="small"
          disableRipple
          onClick={async () => {
            await navigator.clipboard.writeText(
              shareGalleryMessageRef.current.innerText
            );
            enqueueSnackbar("Text copied", {
              autoHideDuration: 1000,
              variant: "info",
            });
          }}
          onClickProps={{ children: "Copied", disabled: true }}
          clickTimeoutSeconds={1}
          sx={{
            minWidth: "auto",
            padding: "0rem 1rem",
          }}
        >
          Copy
        </ReactionButton>
      </Box>
    </Box>
  );
}
