export function fileExtensionToMimetype(
  extension,
  { throwOnUnrecognizedExtension } = {}
) {
  extension = extension.toLowerCase().replace(/^.*\./, "");

  switch (extension) {
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "webp":
    case "heic":
    case "heif":
    case "avif":
      return `image/${extension}`;
    case "mp3":
      return `audio/${extension}`;
    case "mp4":
    case "webm":
      return `video/${extension}`;
    default:
      if (throwOnUnrecognizedExtension) {
        throw new Error(`Unrecognized extension: ${extension}`);
      } else {
        return null;
      }
  }
}

// `image/jpeg` => `jpeg`
// `text/csv; charset=utf-8` => `csv`
export function mimetypeToFileExtension(mimetype) {
  return mimetype.replace(/;.*$/, "").split("/")[1].toLowerCase();
}

export function mimetypeToWeddingMediaType(mimetype) {
  return /^video/.test(mimetype)
    ? "VIDEO"
    : /^audio/.test(mimetype)
    ? "AUDIO"
    : /^image/.test(mimetype)
    ? "IMAGE"
    : null;
}

export function fileExtensionToWeddingMediaType(extension) {
  return mimetypeToWeddingMediaType(fileExtensionToMimetype(extension));
}
