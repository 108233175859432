import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import React from "react";

import {
  LoginPageDesktop,
  LoginPageMobile,
  WeddingsListPage,
  WeddingPage,
} from "./pages";
import TopLevelModalsWrapper from "@@contexts/TopLevelModalsWrapper";
import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";

export default function Router() {
  const theme = useTheme();
  const loggedInUser = React.useContext(LoggedInUserContext);

  const isMdPlusScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <BrowserRouter>
      {/* some top-level modals depend on useHistory, which
          is only available inside <BrowserRouter> */}
      <TopLevelModalsWrapper>
        <Switch>
          <Route exact path="/login">
            {loggedInUser ? (
              <Redirect to="/weddings" />
            ) : isMdPlusScreen ? (
              <LoginPageDesktop />
            ) : (
              <LoginPageMobile />
            )}
          </Route>

          <Route exact path="/weddings">
            {loggedInUser ? (
              <WeddingsListPage sx={{ flexGrow: 1 }} />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route path="/weddings/:weddingIdOrSlug">
            <WeddingPage sx={{ flexGrow: 1 }} />
          </Route>

          <Route>
            <Redirect to="/weddings" />
          </Route>
        </Switch>
      </TopLevelModalsWrapper>
    </BrowserRouter>
  );
}
