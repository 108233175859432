import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import React from "react";
import { useRouteMatch } from "react-router-dom";

import CustomModal from "@@components/common/CustomModal";
import { CurrentWeddingMediaUploadInputsContext } from "@@contexts/CurrentWeddingContexts";
import {
  WeddingPageAddMediaModalOpenContext,
  WeddingPageModalsStateSettersContext,
} from "@@contexts/WeddingPageLevelModalContexts";

import dragAndDropFilesWebm from "@@img/drag_and_drop_files.webm";
import dragAndDropFoldersWebm from "@@img/drag_and_drop_folders.webm";

export default function AddMediaModal() {
  const routeMatch = useRouteMatch("/weddings/:weddingId/:tabName?");

  const open = React.useContext(WeddingPageAddMediaModalOpenContext);
  const { setAddMediaModalOpen } = React.useContext(
    WeddingPageModalsStateSettersContext
  );
  const { allMediaUploadInputRef, photoUploadInputRef, videoUploadInputRef } =
    React.useContext(CurrentWeddingMediaUploadInputsContext);

  const [showingVideo, setShowingVideo] = React.useState(null);

  const { tabName } = routeMatch.params;

  return (
    <CustomModal
      className="add-wedding-media-modal"
      open={open}
      onClose={() => setAddMediaModalOpen(false)}
      BoxProps={showingVideo ? { sx: { width: "30rem" } } : {}}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            "& button": {
              alignSelf: "flex-start",
              padding: 0,
              minWidth: "auto",
            },
            "& video": {
              boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Typography>
            Upload photos and videos to your gallery by dragging them from your
            computer into the Fotobot web page.
          </Typography>

          <Button
            onClick={() =>
              setShowingVideo((cur) =>
                cur === "dragAndDropFiles" ? null : "dragAndDropFiles"
              )
            }
          >
            {showingVideo === "dragAndDropFiles" ? "Hide" : "Show"}
          </Button>

          {showingVideo === "dragAndDropFiles" ? (
            <Box component="video" autoPlay loop>
              <source src={dragAndDropFilesWebm} />
            </Box>
          ) : null}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            "& button": {
              alignSelf: "flex-start",
              padding: 0,
              minWidth: "auto",
            },
            "& video": {
              boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Typography>You can also upload entire folders this way.</Typography>

          <Button
            onClick={() =>
              setShowingVideo((cur) =>
                cur === "dragAndDropFolders" ? null : "dragAndDropFolders"
              )
            }
          >
            {showingVideo === "dragAndDropFolders" ? "Hide" : "Show"}
          </Button>

          {showingVideo === "dragAndDropFolders" ? (
            <Box component="video" autoPlay loop>
              <source src={dragAndDropFoldersWebm} />
            </Box>
          ) : null}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <Typography>Or, just:</Typography>
          <Button
            size="medium"
            variant="outlined"
            onClick={() => {
              (tabName === "photos"
                ? photoUploadInputRef
                : tabName === "videos"
                ? videoUploadInputRef
                : allMediaUploadInputRef
              ).current.click();
            }}
          >
            Select files directly
          </Button>
        </Box>

        <Box>
          <FormControlLabel
            control={<Checkbox />}
            label="Don't show this hint again"
            onChange={(e) => {
              if (e.target.checked) {
                localStorage.setItem("hideAddMediaHints", "true");
              } else {
                localStorage.removeItem("hideAddMediaHints");
              }
            }}
          />
        </Box>
      </Box>
    </CustomModal>
  );
}
