import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";
import React from "react";
import { Set as ImmutableSet } from "immutable";

import { photoMaxWidthInGallery } from "@@config";
import CloseButton from "@@components/common/Buttons/CloseButton";
import Carousel from "@@components/common/Carousel";
import Masonry from "@@components/common/MemoizedMasonry";
import { MemoizedWeddingGalleryItem } from "./WeddingGalleryItem";
import useWindowDimensions from "@@hooks/useWindowDimensions";
import { getUrlOfResizedImage, prefetchPhoto } from "@@utils";
import { pxToRem, remToPx } from "@@utils/webApiUtils";
import WeddingPhotoCarouselElement from "./WeddingPhotoCarouselElement";

export default function WeddingGallery({
  media,
  mediaFilters,
  pileSimilarPhotos,
}) {
  const theme = useTheme();
  const { width: windowWidthPx } = useWindowDimensions();

  const [lastClickedMediaId, setLastClickedMediaId] = React.useState(null);

  // elems of this set are combos of mediaId and imgWidth (ex: "1-512")
  const [prefetchedMedia, setPrefetchedMedia] = React.useState(ImmutableSet());
  const [carouselModalOpen, setCarouselModalOpen] = React.useState(false);
  const handleCarouselModalOpen = (clickedMediaId) => {
    setLastClickedMediaId(clickedMediaId);
    setCarouselModalOpen(true);
  };
  const handleCarouselModalClose = () => {
    setLastClickedMediaId(null);
    setCarouselModalOpen(false);
  };

  const computeMasonryDisplayParameters = React.useCallback(
    _computeMasonryDisplayParameters,
    [theme, windowWidthPx]
  );

  React.useEffect(() => {
    document.addEventListener("keydown", handleEsc);
    return () => document.removeEventListener("keydown", handleEsc);

    function handleEsc(e) {
      if (e.key === "Escape") handleCarouselModalClose();
    }
  }, []);

  React.useEffect(() => {
    handleCarouselModalClose();
  }, [mediaFilters]);

  // pre-compute img widths so we can specify the images' width and height
  //   attrs up-front, which is necessary for lazy-loading to work properly
  const { nCols, sidePaddingRem, masonrySpacing, imgWidthPx } = React.useMemo(
    computeMasonryDisplayParameters,
    [computeMasonryDisplayParameters]
  );

  // The Masonry component is very expensive to render/re-render (expensive
  //   enough to cause serious jank on page), and it keeps re-rendering when
  //   it doesn't need to (i.e. when user clicks on a photo and
  //   lastClickedMediaId changes), so we're memoizing some things to
  //   prevent that

  const groups = React.useMemo(() => {
    let groups;
    if (pileSimilarPhotos) {
      groups = _.groupBy(media, (m) => m.similarPhotosGroupId);
      const ungrouped = groups[null] ?? [];
      delete groups[null];
      groups = Object.values(groups)
        .concat(ungrouped.map((m) => [m]))
        .sort((a, b) => b[0].id - a[0].id);
    } else {
      groups = media.map((m) => [m]);
    }

    // ensure that a group has the same key even if the photo whose id was
    //   the key is deleted
    // ensure that groups with all photos deleted are not passed as children
    //   to the masonry component
    const idsOfPhotosToHide = new Set(
      media
        .filter(
          (m) =>
            m.__deleteMarker ||
            // if isPublic filter is undefined, can show both
            (mediaFilters.isPublic === true && !m.isPublic) ||
            (mediaFilters.isPublic === false && m.isPublic)
        )
        .map(({ id }) => id)
    );
    groups = groups
      .map((group) => ({
        key: group[0].id,
        photos: group.filter((p) => !idsOfPhotosToHide.has(p.id)),
      }))
      .filter((group) => group.photos.length > 0);

    return groups;
  }, [media, mediaFilters, pileSimilarPhotos]);

  const galleryItems = React.useMemo(() => {
    return groups.map(({ key, photos: photosInGroup }) => (
      <MemoizedWeddingGalleryItem
        key={key}
        photosInGroup={photosInGroup}
        widthPx={imgWidthPx}
        onClick={handleCarouselModalOpen}
        sx={{
          // when there's a single column of photos, the default margins &
          //   widths leave a small bit of space empty that makes the photos
          //   look vertically-misaligned (slightly) with surrouding content
          // we're getting rid of that extra space here
          ...(nCols === 1
            ? {
                margin: `calc(${theme.spacing(
                  masonrySpacing
                )} / 2) 0 !important`,
                width: "100% !important",
              }
            : {}),
          // assigning a randomly high order number that will be over-ridden
          //   within the Masonry component
          // this is so newly added items don't default to 'order: 0', which would
          //   cause them to be visually displayed before existing Masonry items
          //   instead of after them, before the Masonry component assigns them the
          //   correct order value
          // not doing this may be causing issues with scroll anchoring, since
          //   the anchor element (usually the last pre-existing Masonry item)
          //   will have its position changed
          // see screenshot: https://imgur.com/a/t1vLqXV
          order: 99,
        }}
      />
    ));
  }, [groups, imgWidthPx, nCols, theme, masonrySpacing]);

  // make the gallery viewing experience smoother by prefetching photos
  //   on either side of the photo currently being viewed
  React.useEffect(() => {
    if (lastClickedMediaId === null) return;

    const idx = groups.findIndex(({ photos }) =>
      photos.find(({ id }) => id === lastClickedMediaId)
    );

    const toPrefetch = [idx - 3, idx - 2, idx - 1, idx + 1, idx + 2, idx + 3]
      .filter((i) => i >= 0 && i < groups.length)
      .flatMap((i) => groups[i].photos)
      .map(({ id, url, otherData }) => {
        const usableVariants = otherData.variants.filter(
          (w) => w <= photoMaxWidthInGallery
        );
        const desiredWidth =
          usableVariants.find((w) => w >= windowWidthPx) ||
          usableVariants.at(-1) ||
          otherData.variants.at(usableVariants.length);

        return {
          identifier: `${id}-${desiredWidth}`,
          url:
            desiredWidth === otherData.variants.at(-1)
              ? url
              : getUrlOfResizedImage(url, desiredWidth),
        };
      })
      .filter(({ identifier }) => !prefetchedMedia.has(identifier));

    toPrefetch.forEach((photo) => {
      prefetchPhoto(photo.url);
      setPrefetchedMedia((prev) => prev.add(photo.identifier));
    });
  }, [lastClickedMediaId, groups, prefetchedMedia, windowWidthPx]);

  return (
    <>
      <Masonry
        className="wedding-media-container"
        columns={nCols}
        spacing={masonrySpacing}
        sx={{
          margin: 0,
          padding: `0 ${sidePaddingRem}rem`,
          ...(nCols === 1
            ? {
                "& .wedding-gallery-item:first-of-type": {
                  marginTop: "0 !important",
                },
              }
            : {}),
        }}
        children={galleryItems}
      />

      <Modal
        className="wedding-photo-carousel-modal"
        open={carouselModalOpen}
        onClose={handleCarouselModalClose}
        BackdropProps={{ style: { backgroundColor: "black", opacity: 0.8 } }}
      >
        <>
          <CloseButton
            onClick={handleCarouselModalClose}
            sx={{
              position: "absolute",
              top: "0.5rem",
              right: "0.5rem",
              zIndex: 100, // render above modal backdrop and content
              padding: "0.25rem",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              color: "grey.light",
              "& :hover": { color: "white" },
            }}
          />

          <Carousel
            elems={groups}
            initialSelectedElemIdx={groups.findIndex(({ photos }) =>
              photos.some((p) => p.id === lastClickedMediaId)
            )}
            renderElem={({ photos }, props, ref) => (
              <WeddingPhotoCarouselElement
                ref={ref}
                {...props}
                photos={photos}
                selectedMediaId={lastClickedMediaId}
                setSelectedMediaId={setLastClickedMediaId}
                onClose={handleCarouselModalClose}
              />
            )}
            onChange={(group) => setLastClickedMediaId(group.key)}
          />
        </>
      </Modal>
    </>
  );

  function _computeMasonryDisplayParameters() {
    const nCols =
      windowWidthPx >= theme.breakpoints.values.xxl
        ? 5
        : windowWidthPx >= theme.breakpoints.values.lg
        ? 4
        : windowWidthPx >= theme.breakpoints.values.md
        ? 3
        : windowWidthPx >= theme.breakpoints.values.sm
        ? 2
        : 1;
    // on mobile, app bar does not take any width at sides
    const appbarWidthRem = windowWidthPx >= theme.breakpoints.values.md ? 4 : 0;
    const sidePaddingRem = 1;
    const masonrySpacing = 1;
    const colGapRem = pxToRem(+theme.spacing(masonrySpacing).replace("px", ""));
    const imgWidthPx =
      (windowWidthPx -
        remToPx(appbarWidthRem) -
        remToPx(sidePaddingRem * 2) -
        remToPx((nCols - 1) * colGapRem)) /
      nCols;

    return { nCols, sidePaddingRem, masonrySpacing, imgWidthPx };
  }
}
