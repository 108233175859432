import cx from "classnames";
import React from "react";
import { photoMaxWidthInGallery } from "@@config";
import { getUrlOfResizedImage } from "@@utils";

function WeddingImage({ media, className, alt, style, ...restProps }, ref) {
  const usableVariants = media.otherData.variants.filter(
    (v) => v <= photoMaxWidthInGallery
  );

  // Using '<img ...' instead of '<Box component="img" ...' here
  //   because Box doesn't pass 'width' and 'height' attrs to the
  //   underlying DOM element, which causes layout shifts when
  //   images are lazy-loaded.
  return (
    <img
      className={cx("wedding-image", className)}
      ref={ref}
      // WARN: for lazy-loading to work in Firefox, the 'loading'
      //   attr must be specified before srcset.
      // See: https://bugzilla.mozilla.org/show_bug.cgi?id=1647077
      //   and https://bugzilla.mozilla.org/show_bug.cgi?id=1628894
      loading="lazy"
      crossOrigin="anonymous"
      src={media.url}
      {...(media.otherData.variants
        ? {
            srcSet: media.otherData.variants
              .map((dim, idx) => {
                if (idx < media.otherData.variants.length - 1) {
                  return `${getUrlOfResizedImage(media.url, dim)} ${dim}w`;
                } else {
                  return `${media.url} ${dim}w`;
                }
              })
              .join(","),
            sizes: usableVariants
              .sort((a, b) => a - b)
              .map((dim, idx) =>
                idx < usableVariants.length - 1
                  ? `(max-width: ${dim}px) ${dim}px`
                  : `${dim}px`
              )
              .join(","),
          }
        : {})}
      alt={alt ?? ""}
      style={{
        // avoiding display: 'inline' to get rid of some annoying whitespace
        //   that was showing up between this img and WeddingMediaOverlay
        //   due to https://stackoverflow.com/q/19038799/3112241
        display: "flex",
        ...style,
      }}
      {...restProps}
    />
  );
}

export default React.forwardRef(WeddingImage);
