import MuiDrawer from "@mui/material/Drawer";
import React from "react";

import CloseButton from "@@components/common/Buttons/CloseButton";

const Drawer = React.forwardRef(Drawer_);
Drawer.defaultProps = { PaperProps: {} };
export default Drawer;

function Drawer_(
  { open, onClose, children, PaperProps, hideCloseButton, ...restProps },
  ref
) {
  const { sx: sxPaperProps, ...restPaperProps } = PaperProps;

  return (
    <MuiDrawer
      open={open}
      onClose={onClose}
      anchor="left"
      PaperProps={{
        sx: {
          width: "25rem",
          maxWidth: "90%",
          padding: "3rem 2rem",
          ...sxPaperProps,
        },
        ...restPaperProps,
      }}
      {...restProps}
      ref={ref}
    >
      {hideCloseButton ? null : (
        <CloseButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
            zIndex: 10,
          }}
        />
      )}
      {children}
    </MuiDrawer>
  );
}
