import { Portal } from "@mui/base/Portal";
import FolderIcon from "@mui/icons-material/Folder";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import React from "react";

import BottomBar from "@@components/common/BottomBar";
import { PRIVILEGED_WEDDING_ROLES } from "@@config";
import { CurrentWeddingContext } from "@@contexts/CurrentWeddingContexts";
import { CurrentWeddingFoldersContext } from "@@contexts/CurrentWeddingFoldersContexts";
import {
  DropTargetFolderContext,
  SetDropTargetFolderContext,
} from "@@contexts/DropTargetFolderContexts";
import { LoggedInUserContext } from "@@contexts/LoggedInUserContextsWrapper";
import { WeddingMediaUploadStatsDispatchContext } from "@@contexts/WeddingMediaUploadStatsContextsWrapper";
import { WeddingMediaAppliedFiltersContext } from "@@contexts/WeddingMediaFiltersContexts";
import { addWeddingMedia } from "@@services/wedding-media.service";
import { getHumanFriendlyFolderName } from "@@utils";
import { fileExtensionToWeddingMediaType } from "@@utils/filetypeUtils";
import { dataTransferItemsToFiles } from "@@utils/webApiUtils";

export default function WeddingMediaDropzone({ sx, children }) {
  const loggedInUser = React.useContext(LoggedInUserContext);
  const wedding = React.useContext(CurrentWeddingContext);
  const weddingFolders = React.useContext(CurrentWeddingFoldersContext);
  const uploadStatsDispatch = React.useContext(
    WeddingMediaUploadStatsDispatchContext
  );
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const appliedMediaFilters = React.useContext(
    WeddingMediaAppliedFiltersContext
  );

  const [dragEntered, setDragEntered] = React.useState(false);
  // NOTE: DRAG_COUNTER_REF
  // dragenter and dragleave events are triggered on entering/leaving
  //   child elements of the dropzone, so we need to use the "counter" hack
  //   to figure out when the dropzone has truly been left
  // https://codesandbox.io/s/play--html-drag-and-drop-api-ovgybs?file=/src/styles.css
  // https://stackoverflow.com/q/10867506/3112241
  // https://www.quirksmode.org/blog/archives/2009/09/the_html5_drag.html
  const dragCounterRef = React.useRef(0);
  const [dropTargetFolder, setDropTargetFolder] = React.useState(null);

  const curMediaPath = appliedMediaFilters.mediaPath ?? "";
  const defaultDropTargetFolder = weddingFolders.find((f) =>
    curMediaPath
      ? f.folderName === curMediaPath
      : f.otherData.targetUsers ===
        (wedding.role === "PHOTOGRAPHER" ? "photographers" : "guests")
  );
  const calculatedDropTargetFolder =
    dropTargetFolder ?? defaultDropTargetFolder;
  const ultimateParentFolderOfDropTargetFolder = weddingFolders.find(
    (f) => f.folderName === calculatedDropTargetFolder.folderName.split("/")[0]
  );
  const userIsAllowedToAddFilesToDropTarget =
    loggedInUser?.admin ||
    PRIVILEGED_WEDDING_ROLES.includes(wedding.role) ||
    (wedding.role === "PHOTOGRAPHER" &&
      ultimateParentFolderOfDropTargetFolder?.otherData?.targetUsers ===
        "photographers") ||
    (wedding.role !== "PHOTOGRAPHER" &&
      ultimateParentFolderOfDropTargetFolder?.otherData?.targetUsers ===
        "guests");

  return (
    <DropTargetFolderContext.Provider value={dropTargetFolder}>
      <SetDropTargetFolderContext.Provider value={setDropTargetFolder}>
        <Box
          className="wedding-media-dropzone"
          sx={{ width: "100%", height: "100%", ...sx }}
          onDragOver={(e) => {
            // console.log("WeddingMediaDropzone: dragOver");
            e.preventDefault();
          }}
          onDragEnter={(e) => {
            // console.log("WeddingMediaDropzone: dragEnter");
            e.preventDefault();
            dragCounterRef.current++;
            setDragEntered(true);
          }}
          onDragLeave={(e) => {
            // console.log("WeddingMediaDropzone: dragLeave");
            e.preventDefault();
            if (--dragCounterRef.current === 0) {
              setDragEntered(false);
            }
          }}
          onDrop={async (e) => {
            // console.log("WeddingMediaDropzone: drop");
            e.preventDefault();
            dragCounterRef.current = 0;
            setDragEntered(false);
            setDropTargetFolder(null);

            if (!userIsAllowedToAddFilesToDropTarget) {
              enqueueSnackbar(`Sorry, that operation is not allowed.`, {
                variant: "error",
              });
              return;
            }

            const filesDropped = await dataTransferItemsToFiles(
              e.dataTransfer.items
            );
            const mediaFilesDropped = filesDropped.filter((f) =>
              ["IMAGE", "VIDEO"].includes(
                fileExtensionToWeddingMediaType(f.name)
              )
            );
            if (mediaFilesDropped.length === 0) return;

            try {
              await addWeddingMedia(
                wedding.id,
                mediaFilesDropped,
                {
                  parentFolderPathBase64: btoa(
                    dropTargetFolder?.folderName ??
                      defaultDropTargetFolder.folderName
                  ),
                },
                uploadStatsDispatch
              );
            } catch (e) {
              enqueueSnackbar(
                e.response?.data?.error ??
                  `Some error occurred. Please retry the upload.`,
                { variant: "error" }
              );
              console.error(e);
            }
          }}
        >
          {children}
        </Box>

        {dragEntered ? (
          <Box
            className="cover-screen"
            sx={{
              zIndex: 1000,
              backgroundColor: "primary.light",
              opacity: 0.8,
              pointerEvents: "none",
            }}
          />
        ) : null}

        {dragEntered ? (
          <Portal>
            <BottomBar
              sx={{
                backgroundColor: theme.palette.grey.extralight,
                padding: "0.5rem 1rem",
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: theme.palette.grey.dark }}
              >
                {userIsAllowedToAddFilesToDropTarget
                  ? `Drop files/folders here to add them to `
                  : `Sorry, you cannot add files to `}
                <Box
                  component="span"
                  sx={{
                    color: userIsAllowedToAddFilesToDropTarget
                      ? theme.palette.grey.dark
                      : theme.palette.error.dark,
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  <FolderIcon
                    sx={{
                      position: "relative",
                      top: "0.25rem",
                      marginRight: "0.25rem",
                      color: userIsAllowedToAddFilesToDropTarget
                        ? theme.palette.grey.main
                        : "red",
                      fontSize: "1.2rem",
                    }}
                  />
                  {getHumanFriendlyFolderName(
                    calculatedDropTargetFolder.folderName
                  )}
                </Box>
              </Typography>
            </BottomBar>
          </Portal>
        ) : null}
      </SetDropTargetFolderContext.Provider>
    </DropTargetFolderContext.Provider>
  );
}
