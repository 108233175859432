import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import React from "react";

import Sentry from "./sentry";
import theme from "./theme";
import EventBus from "./EventBus";
import Router from "@@components/Router";
import EventBusContext from "@@contexts/EventBusContext";
import SentryContext from "@@contexts/SentryContext";
import LoggedInUserContextsWrapper from "@@contexts/LoggedInUserContextsWrapper";
import WeddingMediaUploadStatsContextsWrapper from "@@contexts/WeddingMediaUploadStatsContextsWrapper";
import { WeddingMediaDisplayPrefsProvider } from "@@contexts/WeddingMediaDisplayPrefs";
import PWAInstallSupportContextsWrapper from "@@contexts/PWAInstallSupportContextsWrapper";
import { loginWithGoogle } from "@@services/auth.service";
import { delayUntil } from "@@utils";

const eventBus = new EventBus();

function App() {
  React.useEffect(() => {
    (async () => {
      await delayUntil(() => window.google, "waiting for google auth lib");
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_APP_CLIENT_ID,
        ux_mode: "popup",
        callback: onSignInWithGoogle,
      });
    })();
  }, []);

  return (
    <SentryContext.Provider value={Sentry}>
      <SnackbarProvider maxSnack={3} disableWindowBlurListener>
        <CssBaseline />

        <ThemeProvider theme={theme}>
          <EventBusContext.Provider value={eventBus}>
            <PWAInstallSupportContextsWrapper>
              <LoggedInUserContextsWrapper>
                <WeddingMediaUploadStatsContextsWrapper>
                  <WeddingMediaDisplayPrefsProvider>
                    <Router />
                  </WeddingMediaDisplayPrefsProvider>
                </WeddingMediaUploadStatsContextsWrapper>
              </LoggedInUserContextsWrapper>
            </PWAInstallSupportContextsWrapper>
          </EventBusContext.Provider>
        </ThemeProvider>
      </SnackbarProvider>
    </SentryContext.Provider>
  );
}

async function onSignInWithGoogle({ credential }) {
  const { data: user } = await loginWithGoogle(credential);
  eventBus.publish("login", { user, method: "google" });
}

export default App;
